// import { Pivot, PivotItem } from '@fluentui/react/lib/Pivot';
import { mergeStyleSets } from '@fluentui/react/lib/Styling';
import React from 'react';
import DispositionCalendar from './DispositionCalendar';

const classNames = mergeStyleSets({
    root: {
        display: 'flex',
        flexDirection: 'row',
        background: '#F1F1F1',
        width: '100%'
    },
});

const Planning = () => {
    return (
        <div className={classNames.root}>
            <div className={classNames.pageContentContainer}>
                <div className={classNames.pageInnerContainer}>
                    <DispositionCalendar />
                </div>
            </div>
        </div>   
    )
}

export default Planning;
