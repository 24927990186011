/* eslint-disable no-unused-expressions */
import { mergeStyleSets } from "@fluentui/react/lib/Styling";
import { datesGenerator } from "dates-generator";
import "moment/locale/da";
import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useBodyClass } from "../../../hooks";
import { getHolidayList } from "../../../redux/app/app.actions";
import {
  getMachines,
  selectNewDate,
} from "../../../redux/machines/machines.actions";
import { getPlans } from "../../../redux/plan/plan.actions";
import { getStaffs } from "../../../redux/user/user.actions";
import {
  CALENDAR_PAGE,
  CALENDAR_STEP_WIDTH,
  CALENDAR_WEEKVIEW_STEP_WIDTH,
  hasEditRight,
  months,
  techDepartmentOptions,
} from "../../../utils/constants";
import {
  calculateFinalDates,
  createVerticalPositionMap,
  findStaffIndexAndCounter,
  findYearOfDates,
  getCalendarHoliday,
  getLastDate,
  getMonthIndicator,
  removeFirstWeek,
  startCalendarFromMonth,
  startCalendarFromSelectedWeek,
} from "../../../utils/utils";
import {
  CalendarControlsRight,
  CalendarHeaderLeft,
  DayLabel,
  MonthLabel,
  WeekLabel,
} from "../../common/calendar";
import PlanOverlay from "./planOverlay/PlanOverlay";
import SelectUserOverlay from "./planOverlay/SelectUserOverlay";
import StaffPlan from "./StaffPlan";
import StaffVacationItem from "./StaffVacationItem";
// import { getCrewServiceSchedule, getServiceText } from "../../../utils/service";
import StaffMachineServiceItem from "./StaffMachineServiceItem";

export const getCrewServiceSchedule = (userId, machines, val) => {
  const arr = [];

  machines.forEach(({ machineId, machineName, machineService }) => {
    (machineService || []).forEach((service, serviceIndex) => {
      const crew = service.crews.find((crew) => crew.id === userId);
      const { reason, comment } = service;
      if (crew) {
        const { start, end } = crew;

        if (
          !(
            val?.ignoreServiceIndex === serviceIndex &&
            val?.ignoreMachineId === machineId
          )
        ) {
          arr.push({
            end,
            start,
            machineId,
            machineName,
            reason,
            comment,
          });
        }
      }
    });
  });

  return arr;
};

export const getServiceText = ({ comment, reason }) =>
  comment + (reason ? ` (${reason})` : "");

// https://dev.to/aibrahim3546/creating-a-custom-calendar-in-react-from-scratch-1hej
// https://gist.github.com/aibrahim3546/dc68f9525f0f206ff08b1d09ee3adab5
const DispositionCalendar = () => {
  const heightOfTop = 100;
  const { displayWeekView, selectedDayOnCalendar } = useSelector(
    (state) => state.machine
  );
  const classNames = mergeStyleSets({
    root: {
      background: "#F1F1F1",
      width: "100%",
    },
    topContainer: {
      display: "flex",
      justifyContent: "space-between",
      position: "fixed",
      top: 0,
      left: 0,
      height: heightOfTop,
      background: "#f1f1f1",
      width: "100vw",
      zIndex: 1004,
    },
    calendarContainer: {
      marginTop: 100,
      display: "flex",
      overflowY: "clip",
    },
    dateContainer: {
      display: "flex",
      position: "sticky",
      top: 0,
      left: 0,
      background: "#f1f1f1",
      zIndex: 1003,
    },
    monthText: {
      fontSize: 26,
      fontWeight: "bold",
      textAlign: "center",
    },
    days: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
    },
    dayOfWeeks: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      marginBottom: 8,
    },
    weeks: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      marginBottom: 4,
    },
    week: {
      minWidth: 175,
      height: 20,
      background: "#E7E7E7",
      fontSize: 11,
      lineHeight: 20,
      borderRadius: 10,
    },
    machineList: {
      margin: "70px 0 0 100px",
      display: "flex",
    },
    machineNameContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: 180,
      height: 40,
      background: "#dfdfdf",
      borderRadius: 4,
      marginRight: 20,
    },
    machineName: {
      fontWeight: 700,
      fontSize: 14,
      width: "90%",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      textAlign: "center",
    },
    closeButtonOutside: {
      position: "sticky",
      top: 0,
      left: 12,
      cursor: "pointer",
      background: "#DFDFDF",
      width: "100%",
      zIndex: 100,
      marginBottom: 5,
    },
  });
  useBodyClass(`home`);
  const dispatch = useDispatch();

  const { user, selectedUserId } = useSelector((state) => state.user);
  const { displayStaffVacation } = useSelector((state) => state.machine);
  const { workingRole } = user;
  const allowEdit = hasEditRight(workingRole);
  const isFormandDisponentFunktioner = [0, 3, 4].some((role) =>
    workingRole.includes(role)
  );
  const calendarStartFrom =
    useSelector((state) => state.user.user.defaultFilter.customFilters)?.find(
      (item) => item.key === "calendarStartFrom"
    )?.text || "month";

  const [dates, setDates] = useState([]);
  const [calendar, setCalendar] = useState({});
  const [calendarStepWidth, setCalendarStepWidth] = useState(
    displayWeekView ? CALENDAR_WEEKVIEW_STEP_WIDTH : CALENDAR_STEP_WIDTH
  );
  const { data: usersData } = useQuery("staffs", () => dispatch(getStaffs()));
  const { data: machinesData } = useQuery("machines", () =>
    dispatch(getMachines())
  );

  const { data: allPlansData, refetch: refetchPlans } = useQuery("plans", () =>
    dispatch(getPlans(new Date(selectedDayOnCalendar).getFullYear()))
  );
  const [maxCounter, setMaxCounter] = useState(1);
  const holidayRef = useRef(null);
  const verticalPositionMap = useMemo(
    () => createVerticalPositionMap(dates),
    [dates]
  );

  const onClickChooseDate = (date) => {
    dispatch(selectNewDate(date));
    const array = new Array(displayWeekView ? 12 : 6);
    array[0] = datesGenerator({
      month: date.getMonth(),
      year: date.getFullYear(),
      startingDay: 1,
    });
    for (let i = 1; i < array.length; i++) {
      array[i] = datesGenerator({
        month: array[i - 1].nextMonth,
        year: array[i - 1].nextYear,
        startingDay: 1,
      });
    }
    // let { finalDates, start, end } = calculateFinalDates(array);
    let { finalDates, start, end } = calculateFinalDates(
      array,
      false,
      [0, 4].some((role) => workingRole.includes(role)) ? 12 : 4,
      date
    );
    if (calendarStartFrom === "month" && !displayWeekView) {
      finalDates[0] = startCalendarFromMonth(date, finalDates[0]);
    }
    // Find the start of calendar if choose to start from selected week
    if (calendarStartFrom === "week") {
      finalDates = startCalendarFromSelectedWeek(date, finalDates);
    }
    start = new Date(
      finalDates[0][0].year,
      finalDates[0][0].month,
      finalDates[0][0].date
    );
    // Find the holiday of calendar
    async function getDatesWithHolidays() {
      const yearOfDates = findYearOfDates(finalDates);
      let holidays = [];
      if (
        !holidayRef.current ||
        JSON.stringify(yearOfDates) !==
          JSON.stringify(holidayRef.current?.years)
      ) {
        for (const year of yearOfDates) {
          const currentHolidays = await dispatch(getHolidayList(year));
          holidays = [...holidays, ...(currentHolidays?.holidays || [])];
        }
        holidayRef.current = {
          years: yearOfDates,
          holidays: holidays,
        };
      } else {
        holidays = holidayRef.current.holidays;
      }
      finalDates = getCalendarHoliday(finalDates, holidays);
      setDates(finalDates);
    }
    getDatesWithHolidays();
    setDates(finalDates);
    const endDate = getLastDate(finalDates);
    end = new Date(endDate.year, endDate.month, endDate.date);

    setCalendar({
      ...calendar,
      nextMonth: array[array.length - 1].nextMonth,
      nextYear: array[array.length - 1].nextYear,
      previousMonth: array[0].previousMonth,
      previousYear: array[0].previousYear,
      start,
      end,
    });
  };

  useEffect(() => {
    refetchPlans();
    setCalendarStepWidth(
      displayWeekView ? CALENDAR_WEEKVIEW_STEP_WIDTH : CALENDAR_STEP_WIDTH
    );
    onClickChooseDate(new Date(selectedDayOnCalendar));
  }, [
    displayWeekView,
    new Date(selectedDayOnCalendar).toLocaleString(),
    calendarStartFrom,
  ]);

  // Add icon to resize cursor
  useEffect(() => {
    setTimeout(() => {
      const resizeDivList = document.getElementsByClassName("react-draggable");
      for (let index = 0; index < resizeDivList.length; index++) {
        const resizeDiv = resizeDivList[index].lastChild;
        if (resizeDiv.firstChild && resizeDiv.lastChild) {
          resizeDiv.firstChild.style.cursor = allowEdit
            ? `url(${window.location.origin}/img/arrows-alt-h.svg), auto`
            : "default";
          resizeDiv.firstChild.style.left = "-14px";
          resizeDiv.lastChild.style.cursor = allowEdit
            ? `url(${window.location.origin}/img/arrows-alt-h.svg), auto`
            : "default";
          resizeDiv.lastChild.style.right = "3px";
        }
      }
    }, 500);
  });

  const filterStaffPlans = (usersData) => {
    const userData = usersData.find((user) => user.userId === selectedUserId);
    const result = { plan: [], vacation: [], serviceSchedules: [] };
    const startDate = dates[0][0];
    const endDate = getLastDate(dates);
    const startCalendar = new Date(
      startDate.year,
      startDate.month,
      startDate.date
    );
    const endCalendar = new Date(endDate.year, endDate.month, endDate.date);
    if (allPlansData?.length > 0) {
      allPlansData.forEach((plan) => {
        if (!plan.inactive) {
          plan.machineRequirements.forEach(
            (machineRequirement, indexMachineRequirement) => {
              [
                machineRequirement["drivers"],
                machineRequirement["workers"],
                machineRequirement["managers"],
              ].forEach((array) => {
                array.forEach((item) => {
                  const staffStartDate = new Date(item.start);
                  const staffEndDate = new Date(item.end);

                  if (
                    userData.userId === item.id &&
                    staffStartDate <= endCalendar &&
                    staffEndDate >= startCalendar
                  ) {
                    // let isDone = true;
                    // if (
                    //     [machineRequirement?.drivers ?? [], machineRequirement?.workers ?? [], machineRequirement?.managers ?? []].some(
                    //         (array) => array.length === 0
                    //     ) &&
                    //     !machineRequirement?.done
                    // ) {
                    //     isDone = false;
                    // }

                    result.plan.push({
                      ...item,
                      machines: machineRequirement.machines.filter((el) => {
                        return (
                          staffStartDate >= new Date(el.start) &&
                          staffEndDate <= new Date(el.end)
                        );
                      }),
                      personResponsbible: plan?.personResponsbible?.name,
                      isQuotation: plan.isQuotation,
                      projectNo: plan.projectNo,
                      projectId: plan.projectId,
                      projectName: plan.projectName,
                      hovedsagsNummer: plan.hovedsagsNummer,
                      color: plan.color,
                      indexMachineRequirement,
                      isHidden: machineRequirement.isHidden,
                    });
                  }
                });
              });
            }
          );
        }
      });
    }
    if (result.plan.length) {
      const { positionAndCounter, currentCounter } = findStaffIndexAndCounter(
        result.plan,
        0
      );
      setMaxCounter(currentCounter);
      result.plan = result.plan.map((item, index) => ({
        ...item,
        counter: positionAndCounter[index],
      }));
    }
    if (userData.starfVacations) {
      result.vacation = userData.starfVacations;
    }

    const serviceSchedules = getCrewServiceSchedule(
      userData.userId,
      machinesData
    );

    result.serviceSchedules = serviceSchedules;

    return result;
  };

  const userPlanAndVacation = useMemo(
    () =>
      usersData && machinesData && dates.length
        ? filterStaffPlans(usersData)
        : { plan: [], vacation: [], serviceSchedules: [] },
    [usersData, allPlansData, selectedUserId, dates, machinesData]
  );

  return usersData ? (
    <div>
      <PlanOverlay />
      <SelectUserOverlay usersData={usersData} />
      <div className={classNames.topContainer}>
        <CalendarHeaderLeft usersData={usersData} />
        <CalendarControlsRight
          onClickChooseDate={onClickChooseDate}
          calendarPage={CALENDAR_PAGE.DISPOSITION}
          isFormandDisponentFunktioner={isFormandDisponentFunktioner}
        />
      </div>
      <div className={classNames.calendarContainer}>
        <div className={classNames.dateContainer}>
          <div className={classNames.weeks} style={{ width: 20, marginTop: 5 }}>
            {dates.length &&
              getMonthIndicator(dates, months).map((monthData, indexMonth) => (
                <MonthLabel
                  isWeekView={displayWeekView}
                  index={indexMonth}
                  key={indexMonth + monthData["length"] + monthData["label"]}
                  length={monthData["length"]}
                  label={monthData["label"]}
                />
              ))}
          </div>

          <div
            className={classNames.weeks}
            style={{
              marginLeft: 10,
              // Calculate position for first week
              marginTop:
                calendarStartFrom === "month" &&
                !displayWeekView &&
                Array.isArray(dates) &&
                dates[0]?.length < 7
                  ? dates[0].length * CALENDAR_STEP_WIDTH
                  : 0,
            }}
          >
            {dates.length > 0 &&
              removeFirstWeek(
                dates,
                calendarStartFrom === "month" &&
                  !displayWeekView &&
                  Array.isArray(dates) &&
                  dates[0]?.length < 7
              ).map((week, indexWeek) => (
                <WeekLabel
                  isWeekView={displayWeekView}
                  index={indexWeek}
                  month={week[0]["month"]}
                  day={week[0]["date"]}
                  year={week[0]["year"]}
                  key={`${week[0]["month"]}-${week[0]["date"]}-${week[0]["year"]}`}
                />
              ))}
          </div>
          {!displayWeekView && (
            <>
              <div className={classNames.days}>
                {dates.length > 0 &&
                  dates.map((week) =>
                    week.map((each) => (
                      <DayLabel
                        label={each.date}
                        date={each}
                        key={JSON.stringify(each)}
                      />
                    ))
                  )}
              </div>
              <div className={classNames.dayOfWeeks}>
                {dates.length > 0 &&
                  dates.map((week) =>
                    week.map((each) => (
                      <DayLabel
                        label={each.date}
                        date={each}
                        key={JSON.stringify(each)}
                        isDaysOfWeek
                      />
                    ))
                  )}
              </div>
            </>
          )}
        </div>

        <div
          style={{
            minHeight: "calc(100vh - 305px)",
            height: calendarStepWidth * 7 * dates.length - 24,
            width:
              maxCounter * 200 > document.documentElement.offsetWidth - 125
                ? maxCounter * 200
                : "calc(100vw - 125px)",
            backgroundImage: 'url("./img/calendarBackground3-vertical.png")',
            backgroundRepeat: "repeat",
            backgroundPositionY:
              calendarStartFrom === "month" &&
              !displayWeekView &&
              Array.isArray(dates) &&
              dates[0]?.length < 7
                ? dates[0].length * CALENDAR_STEP_WIDTH
                : 0,
          }}
        >
          <div
            id="planContainer"
            className="planContainer"
            style={{
              position: "absolute",
              top: 100,
              left: 100,
              zIndex: 556,
              height: calendarStepWidth * 7 * dates.length - 24,
            }}
          >
            {dates.length &&
              displayStaffVacation &&
              verticalPositionMap &&
              userPlanAndVacation.vacation.map((item, index) => (
                <Fragment key={`${item.start}-${index}`}>
                  <StaffVacationItem
                    calendarStepWidth={calendarStepWidth}
                    staffVacationIndex={index}
                    staffVacation={item}
                    start={item["start"]}
                    end={item["end"]}
                    verticalPositions={verticalPositionMap}
                    calendarStart={calendar.start}
                    calendarEnd={calendar.end}
                    isApproved={item["isApproved"]}
                    counter={0}
                  />
                </Fragment>
              ))}
            {dates.length &&
              verticalPositionMap &&
              userPlanAndVacation.serviceSchedules.map((item, index) => (
                <Fragment key={`${item.start}-${index}`}>
                  <StaffMachineServiceItem
                    calendarStepWidth={calendarStepWidth}
                    staffVacationIndex={index}
                    start={item["start"]}
                    end={item["end"]}
                    verticalPositions={verticalPositionMap}
                    calendarStart={calendar.start}
                    calendarEnd={calendar.end}
                    isApproved={item["isApproved"]}
                    counter={0}
                    serviceText={getServiceText({
                      comment: item.comment,
                      reason: item.reason,
                    })}
                    serviceItem={item}
                  />
                </Fragment>
              ))}

            {dates.length &&
              verticalPositionMap &&
              userPlanAndVacation.plan.map((staff, index) => (
                <Fragment key={`${staff.id}-${index}`}>
                  <StaffPlan
                    calendarStepWidth={calendarStepWidth}
                    staff={staff}
                    verticalPositions={verticalPositionMap}
                    calendarStart={calendar.start}
                    calendarEnd={calendar.end}
                    counter={staff.counter}
                  />
                </Fragment>
              ))}
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default DispositionCalendar;
