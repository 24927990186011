import axios from "axios";
import { checkTokenValidity } from "../user/user.actions";
import { saveMessage } from "../message/message.actions";
import { MessageBarType } from "@fluentui/react";
import setDefaults from "../../utils/setDefaults";
import { MachinesActionTypes } from "./machines.types";
import { SOURCE_API } from "../../utils/constants";

export const getMachines = () => async (dispatch) => {
  try {
    setDefaults(localStorage.getItem("token"));

    const response = await axios.get(`${SOURCE_API}/api/Machine/getall`);
    return response.data.data;
  } catch (error) {
    dispatch(checkTokenValidity(error));
  }
};

export const getMachine = (machineId) => async (dispatch) => {
  try {
    setDefaults(localStorage.getItem("token"));

    const response = await axios.get(
      `${SOURCE_API}/api/Machine/get?machineIds=${machineId}`
    );
    return response.data.data[0] ? response.data.data[0] : null;
  } catch (error) {
    dispatch(checkTokenValidity(error));
  }
};

export const createMachine = (machineData) => async (dispatch) => {
  try {
    setDefaults(localStorage.getItem("token"));
    const response = await axios.post(`${SOURCE_API}/api/Machine`, machineData);

    if (!response.data.succeeded && response.data.message) {
      throw new Error(response.data.message);
    }
    dispatch(
      saveMessage("Machine successfully created!", MessageBarType.success)
    );

    return response.data.succeeded;
  } catch (error) {
    dispatch(checkTokenValidity(error));
  }
};

export const updateMachine = (machineData) => async (dispatch) => {
  try {
    setDefaults(localStorage.getItem("token"));
    const response = await axios.put(`${SOURCE_API}/api/Machine`, machineData);

    if (!response.data.succeeded && response.data.message) {
      throw new Error(response.data.message);
    }

    dispatch(
      saveMessage("Machine successfully updated!", MessageBarType.success)
    );
    return response.data.succeeded;
  } catch (error) {
    dispatch(checkTokenValidity(error));
  }
};

export const deleteMachine = (machineId) => async (dispatch) => {
  try {
    setDefaults(localStorage.getItem("token"));
    const response = await axios.delete(
      `${SOURCE_API}/api/Machine?machineId=${machineId}`
    );

    if (response.data.succeeded === true) {
      dispatch(saveMessage("Machine deleted", MessageBarType.success));
    } else {
      dispatch(saveMessage("There was an error"));
    }
    return true;
  } catch (error) {
    dispatch(checkTokenValidity(error));
  }
};

export const getTeams = () => async (dispatch) => {
  try {
    setDefaults(localStorage.getItem("token"));

    const response = await axios.get(`${SOURCE_API}/api/MachineTeam/getall`);
    return response.data.data;
  } catch (error) {
    dispatch(checkTokenValidity(error));
  }
};

export const getTeam = (machineId) => async (dispatch) => {
  try {
    setDefaults(localStorage.getItem("token"));

    const response = await axios.get(
      `${SOURCE_API}/api/MachineTeam/get?machineIds=${machineId}`
    );
    return response.data.data[0] ? response.data.data[0] : null;
  } catch (error) {
    dispatch(checkTokenValidity(error));
  }
};

export const getTeamByTeamId = (teamId) => async (dispatch) => {
  try {
    setDefaults(localStorage.getItem("token"));

    const response = await axios.get(
      `${SOURCE_API}/api/MachineTeam/getById?id=${teamId}`
    );
    return response.data.data ? response.data.data : null;
  } catch (error) {
    dispatch(checkTokenValidity(error));
  }
};

export const createTeam = (teamData) => async (dispatch) => {
  try {
    setDefaults(localStorage.getItem("token"));
    const response = await axios.post(
      `${SOURCE_API}/api/MachineTeam`,
      teamData
    );

    if (!response.data.succeeded && response.data.message) {
      throw new Error(response.data.message);
    }
    dispatch(saveMessage("Team successfully created!", MessageBarType.success));

    return response.data.succeeded;
  } catch (error) {
    dispatch(checkTokenValidity(error));
  }
};

export const updateTeam = (teamData) => async (dispatch) => {
  try {
    setDefaults(localStorage.getItem("token"));
    const response = await axios.put(`${SOURCE_API}/api/MachineTeam`, teamData);

    if (!response.data.succeeded && response.data.message) {
      throw new Error(response.data.message);
    }

    dispatch(saveMessage("Team successfully updated!", MessageBarType.success));
    return response.data.succeeded;
  } catch (error) {
    dispatch(checkTokenValidity(error));
  }
};

export const deleteTeam = (teamId) => async (dispatch) => {
  try {
    setDefaults(localStorage.getItem("token"));
    const response = await axios.delete(
      `${SOURCE_API}/api/MachineTeam?machineId=${teamId}`
    );

    if (response.data.succeeded === true) {
      dispatch(saveMessage("Team deleted", MessageBarType.success));
    } else {
      dispatch(saveMessage("There was an error"));
    }
    return true;
  } catch (error) {
    dispatch(checkTokenValidity(error));
  }
};

// export const setTechDepartmentFilter = (techDepartmentId) => dispatch => {
export const setFilter = (filter) => (dispatch) => {
  dispatch({
    type: MachinesActionTypes.CHANGE_FILTER,
    payload: filter,
  });
};

export const toggleDisplayWeekView = (toggleDisplay) => (dispatch) => {
  dispatch({
    type: MachinesActionTypes.TOGGLE_WEEK_VIEW,
    payload: toggleDisplay,
  });
};

export const toggleDisplayStaffVacation = (toggleDisplay) => (dispatch) => {
  const value = typeof toggleDisplay === "object" ? null : toggleDisplay;
  dispatch({
    type: MachinesActionTypes.TOGGLE_STAFF_VACATION,
    payload: value,
  });
};

export const toggleDisplayStaffView = (toggleDisplay) => (dispatch) => {
  dispatch({
    type: MachinesActionTypes.TOGGLE_STAFF_VIEW,
    payload: toggleDisplay,
  });
};

export const selectNewDate = (newDate) => (dispatch) => {
  dispatch({
    type: MachinesActionTypes.SELECT_NEW_MONTH,
    payload: newDate,
  });
};

export const onChangeFilterStaffRole =
  (index, staffRole, techDepartment, staffPage) => (dispatch) => {
    const temp = JSON.parse(JSON.stringify([...staffRole])); // Prevent shallow copy
    temp[index].isOn = !temp[index].isOn;
    const filterStaffRole = staffPage
      ? { staffRoleStaffPage: temp }
      : { [techDepartment]: { staffRole: temp } };
    dispatch(setFilter(filterStaffRole));
  };
