import axios from "axios";
import { UserActionTypes } from "./user.types";
import { saveMessage } from "../message/message.actions";
import setDefaults from "../../utils/setDefaults";
import { MessageBarType } from "@fluentui/react";
import { SOURCE_API } from "../../utils/constants";
import { persistor } from "../store";

//Get all staffs
export const getStaffs = () => async (dispatch) => {
  try {
    setDefaults(localStorage.getItem("token"));

    const response = await axios.get(`${SOURCE_API}/api/User/getall`);

    return response.data.data;
  } catch (error) {
    dispatch(checkTokenValidity(error));
  }
};

//Get all users who missing info
export const getUsersMissingInfo = () => async (dispatch) => {
  try {
    setDefaults(localStorage.getItem("token"));

    const response = await axios.get(
      `${SOURCE_API}/api/User/getallmissinginfo`
    );

    return response.data.data;
  } catch (error) {
    dispatch(checkTokenValidity(error));
  }
};

export const getCurrentUserInfo = (userIds) => async (dispatch) => {
  try {
    setDefaults(localStorage.getItem("token"));

    const response = await axios.get(
      `${SOURCE_API}/api/User/get?ids=${userIds.join(",")}`
    );

    dispatch({
      type: UserActionTypes.GET_CURRENT_USER_INFO,
      payload: response.data.data[0],
    });

    return response.data.data;
  } catch (error) {
    dispatch(checkTokenValidity(error));
  }
};

export const getStaff = (userIds) => async (dispatch) => {
  try {
    setDefaults(localStorage.getItem("token"));

    const response = await axios.get(
      `${SOURCE_API}/api/User/get?ids=${userIds.join(",")}`
    );

    return response.data.data;
  } catch (error) {
    dispatch(checkTokenValidity(error));
  }
};

export const createStaff = (staffData) => async (dispatch) => {
  try {
    setDefaults(localStorage.getItem("token"));
    const response = await axios.post(`${SOURCE_API}/api/User`, staffData);

    if (!response.data.succeeded && response.data.message) {
      throw new Error(response.data.message);
    }

    dispatch(
      saveMessage("Staff successfully created!", MessageBarType.success)
    );

    return response.data.succeeded;
  } catch (error) {
    dispatch(checkTokenValidity(error));
    if (error?.response?.status === 401) {
      dispatch(
        saveMessage(
          "The user session has expired, please login again.",
          MessageBarType.warning
        )
      );
    } else {
      dispatch(saveMessage(error.message));
    }
  }
};

export const syncUser = () => async (dispatch) => {
  try {
    setDefaults(localStorage.getItem("token"));
    const response = await axios.post(`${SOURCE_API}/api/User/SyncADUsers`);

    if (!response.data.succeeded && response.data.message) {
      throw new Error(response.data.message);
    }

    dispatch(
      saveMessage("Synchronization is completed!", MessageBarType.success)
    );

    return response.data.succeeded;
  } catch (error) {
    dispatch(checkTokenValidity(error));
  }
};

export const updateStaff = (staffData) => async (dispatch) => {
  try {
    setDefaults(localStorage.getItem("token"));
    const response = await axios.put(`${SOURCE_API}/api/User`, staffData);

    if (!response.data.succeeded && response.data.message) {
      throw new Error(response.data.message);
    }

    dispatch(
      saveMessage("Staff successfully updated!", MessageBarType.success)
    );

    return response.data.succeeded;
  } catch (error) {
    dispatch(checkTokenValidity(error));
  }
};

// Login User
export const login = (accessToken) => async (dispatch) => {
  try {
    dispatch(setLoading());
    setDefaults();
    axios.defaults.headers.common["authorization"] = `Bearer ${accessToken}`;

    const response = await axios.post(`${SOURCE_API}/Auth/loginandsync`);

    if (!response.data.succeeded && response.data.message) {
      throw new Error(response.data.message);
    }

    const payload = {
      user: response.data.data.identityModel,
      token: "Bearer " + response.data.data.token,
    };

    if (response.data.data.identityModel.workingRole === "-1") {
      dispatch(
        saveMessage(
          "Your account has no role, please contact your manager to be assigned a role!",
          MessageBarType.warning
        )
      );
    }

    dispatch({
      type: UserActionTypes.LOGIN_SUCCESS,
      payload: payload,
    });
  } catch (error) {
    dispatch(saveMessage(error.message));
    dispatch({ type: UserActionTypes.LOGIN_FAIL });
  }
};

export const loginTeams = (accessToken) => async (dispatch) => {
  try {
    dispatch(setLoading());
    setDefaults(null);

    axios.defaults.headers.common["App-Context"] = `BlueApp`;
    axios.defaults.headers.common["authorization"] = `Bearer ${accessToken}`;

    const response = await axios.post(
      `${SOURCE_API}/Auth/loginteamsandsync`,
      null,
      {
        headers: {
          "App-Context": "BlueApp",
        },
      }
    );

    // const response = await axios.post(
    //   `https://localhost:5001/Auth/loginteamsandsync`
    // );

    if (!response.data.succeeded && response.data.message) {
      throw new Error(response.data.message);
    }

    const payload = {
      user: response.data.data.identityModel,
      token: "Bearer " + response.data.data.token,
    };

    if (response.data.data.identityModel.workingRole === "-1") {
      dispatch(
        saveMessage(
          "Your account has no role, please contact your manager to be assigned a role!",
          MessageBarType.warning
        )
      );
    }

    dispatch({
      type: UserActionTypes.LOGIN_SUCCESS,
      payload: payload,
    });
  } catch (error) {
    // @ts-ignore
    dispatch(saveMessage(error.message));
    dispatch({ type: UserActionTypes.LOGIN_FAIL });
  }
};

// Logout User
export const logout = () => async (dispatch) => {
  dispatch({ type: UserActionTypes.LOGOUT_SUCCESS });
  setTimeout(() => persistor.purge(), 200);
};

// Set loading to true
export const setLoading = () => {
  return { type: UserActionTypes.USER_LOADING };
};

export const checkTokenValidity = (error) => (dispatch) => {
  if (
    error.response &&
    error.response.status &&
    error.response.status === 401
  ) {
    localStorage.clear();
    sessionStorage.clear();
    dispatch({ type: UserActionTypes.TOKEN_EXPIRED });
    dispatch(saveMessage("The user session has expired, please login again."));
  }
};

export const updateSettingFilter = (settingData) => (dispatch) => {
  dispatch({
    type: UserActionTypes.UPDATE_SETTING_FILTER,
    payload: settingData,
  });
};

export const changeSectionFilter = (sectionArray) => (dispatch) => {
  dispatch({
    type: UserActionTypes.CHANGE_SECTION_FILTER,
    payload: sectionArray,
  });
};

export const setAutoLogin = (value) => {
  return { type: UserActionTypes.SET_AUTO_LOGIN, payload: value };
};

export const setSelectedUserId = (id) => {
  return { type: UserActionTypes.SET_SELECTED_USER_ID, payload: id };
};

export const setUserOverlay = (boolean) => {
  return { type: UserActionTypes.SET_USER_OVERLAY, payload: boolean };
};
