import { Label } from "@fluentui/react";
import { mergeStyleSets } from "@fluentui/react/lib/Styling";
import { faChevronDown } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setUserOverlay } from "../../../redux/user/user.actions";
import { workingRoleOptions } from "../../../utils/constants";

const classNames = mergeStyleSets({
  header: {
    display: "flex",
    justifyContent: "space-between",
    height: 40,
    margin: `10px 0 40px 20px`,
  },
  title: {
    fontWeight: "bold",
    fontSize: "18px",
    "@media (max-width: 500px)": {
      fontSize: 13,
    },
    cursor: "pointer",
  },
  subTitle: {
    fontSize: "11px",
    fontWeight: "normal",
    lineHeight: "11px",
    marginTop: -15,
    "@media (max-width: 500px)": {
      fontSize: 10,
    },
  },
  container: {
    display: "flex",
    gap: 10,
    alignContent: "center",
    alignItems: "center",
    paddingRight: 10,
    marginBottom: 40,
    cursor: "pointer",
    "@media (max-width: 500px)": {
      marginBottom: 20,
    },
  },
});

const CalendarHeaderLeft = ({ usersData }) => {
  const dispatch = useDispatch();
  const { selectedUserId } = useSelector((state) => state.user);
  // console.log(selectedUserId);
  // console.log(usersData);
  const user = usersData.find((user) => user.userId === selectedUserId);
  const { workingRole } = user;
  const roles =
    workingRole !== "-1"
      ? workingRole
          .split(";")
          .filter((item) => item !== "-1")
          .map((key) => workingRoleOptions[key]?.text)
          .join(", ")
          .toUpperCase()
      : "NO ROLE";

  return (
    <div className={classNames.header}>
      <div>
        <div
          onClick={(e) => {
            e.stopPropagation();
            dispatch(setUserOverlay(true));
          }}
          className={classNames.container}
        >
          <Label className={classNames.title}>
            {`${user.firstName} ${user.lastName}`}
          </Label>
          <FontAwesomeIcon
            icon={faChevronDown}
            style={{ fontSize: 12, color: "black", margin: "0px 0px 2px 7px" }}
          />
        </div>
        <Label className={classNames.subTitle}>{roles}</Label>
      </div>
    </div>
  );
};

export default CalendarHeaderLeft;
