import axios from "axios";
import { checkTokenValidity } from "../user/user.actions";
import { saveMessage } from "../message/message.actions";
import { MessageBarType } from "@fluentui/react";
import { PrebookingActionTypes } from "./prebooking.types";
import setDefaults from "../../utils/setDefaults";
import { SOURCE_API } from "../../utils/constants";

export const getPrebookings = (from, to) => async (dispatch) => {
  try {
    setDefaults(localStorage.getItem("token"));
    if (typeof from !== "string" && typeof to !== "string") {
      from = new Date(new Date().getFullYear() - 1, 0, 1).toISOString();
      to = new Date(new Date().getFullYear() + 1, 11, 31).toISOString();
    }

    const response = await axios.get(
      `${SOURCE_API}/api/Booking/getall?from=${from}&to=${to}`
    );

    return response.data.data;
  } catch (error) {
    dispatch(checkTokenValidity(error));
  }
};

export const getPrebooking = (prebookingId) => async (dispatch) => {
  try {
    setDefaults(localStorage.getItem("token"));

    const response = await axios.get(
      `${SOURCE_API}/api/Booking/get?id=${prebookingId}`
    );
    return response.data.data ? response.data.data : null;
  } catch (error) {
    dispatch(checkTokenValidity(error));
  }
};

export const createPrebooking = (prebookingData) => async (dispatch) => {
  try {
    setDefaults(localStorage.getItem("token"));
    const response = await axios.post(
      `${SOURCE_API}/api/Booking`,
      prebookingData
    );

    if (response.data.succeeded === true) {
      dispatch(
        saveMessage("Prebooking successfully created!", MessageBarType.success)
      );
    } else {
      dispatch(saveMessage("There was an error"));
    }
    return response.data.succeeded;
  } catch (error) {
    dispatch(checkTokenValidity(error));
  }
};

export const updatePrebooking = (prebookingData) => async (dispatch) => {
  try {
    setDefaults(localStorage.getItem("token"));
    const response = await axios.put(
      `${SOURCE_API}/api/Booking`,
      prebookingData
    );

    if (response.data.succeeded === true) {
      dispatch(
        saveMessage("Prebooking successfully updated!", MessageBarType.success)
      );
    } else {
      dispatch(saveMessage("There was an error"));
    }
    return response.data.succeeded;
  } catch (error) {
    dispatch(checkTokenValidity(error));
  }
};

export const deletePrebooking = (prebookingId) => async (dispatch) => {
  try {
    setDefaults(localStorage.getItem("token"));
    const response = await axios.delete(
      `${SOURCE_API}/api/Booking?id=${prebookingId}`
    );

    if (response.data.succeeded === true) {
      dispatch(saveMessage("Prebooking deleted", MessageBarType.success));
    } else {
      dispatch(saveMessage("There was an error"));
    }
    return response.data.succeeded;
  } catch (error) {
    dispatch(checkTokenValidity(error));
  }
};

export const setOverlayPrebookingId = (prebookingId) => async (dispatch) => {
  dispatch({
    type: PrebookingActionTypes.UPDATE_OVERLAY_PREBOOKING_ID,
    payload: prebookingId,
  });
};

export const clearOverlayPrebookingId = () => async (dispatch) => {
  dispatch({
    type: PrebookingActionTypes.CLEAR_OVERLAY_PREBOOKING_ID,
  });
};

export const changePrebookingBubbleInfo = (data) => (dispatch) => {
  dispatch({
    type: PrebookingActionTypes.CHANGE_PREBOOKING_MODAL_INFO,
    payload: data,
  });
};
