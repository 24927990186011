import { Panel } from "@fluentui/react";
import { mergeStyleSets } from "@fluentui/react/lib/Styling";
import { faArrowLeft, faSearch } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { userAutoCompleteStyles } from "../../../../utils/theme";

import {
  setSelectedUserId,
  setUserOverlay,
} from "../../../../redux/user/user.actions";
import { useMemo } from "react";

const classNames = mergeStyleSets({
  buttonBottomContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
  },
  closeButtonOutside: {
    position: "sticky",
    top: 0,
    left: 12,
    cursor: "pointer",
    background: "#DFDFDF",
    width: "100%",
    zIndex: 100,
    marginBottom: 5,
  },
});

const overlayStyle = {
  main: {
    width: "100%",
    background: "#DFDFDF",
    paddingTop: 0,
  },
};

const SelectUserOverlay = ({ usersData }) => {
  const dispatch = useDispatch();
  //   const usersOptions = usersData
  //     .filter((el) => !el.inactive)
  //     .map((user) => ({
  //       key: user.userId,
  //       label: `${user.firstName} ${user.lastName}`,
  //     }));

  const usersOptions = useMemo(() => {
    if (Array.isArray(usersData) && usersData?.length) {
      let data = usersData
        .filter((el) => !el.inactive && el.firstName && el.lastName)
        .map((user) => ({
          key: user.userId,
          label: `${user.firstName} ${user.lastName}`,
        }));

      data.sort((a, b) => {
        const nameA = a.label.toLowerCase(); // Convert to lowercase for case-insensitive sorting
        const nameB = b.label.toLowerCase();

        if (nameA < nameB) {
          return -1; // a should come before b in the sorted order
        }

        if (nameA > nameB) {
          return 1; // a should come after b in the sorted order
        }

        return 0; // a and b are equal
      });

      return data;
    }

    return [];
  }, [usersData]);

  const { selectedUserId, openUserOverlay } = useSelector(
    (state) => state.user
  );
  return (
    <Panel
      isOpen={openUserOverlay}
      hasCloseButton={false}
      customWidth="500px"
      styles={overlayStyle}
    >
      <div
        onClick={() => dispatch(setUserOverlay(false))}
        className={classNames.closeButtonOutside}
      >
        <FontAwesomeIcon
          icon={faArrowLeft}
          style={{ color: "##2A2A2A", fontSize: 20, position: "relative" }}
        />
      </div>
      <div
        style={{ position: "relative" }}
        className={classNames.innerContainer}
      >
        <Select
          placeholder="Søg person"
          closeMenuOnSelect={true}
          defaultValue={usersOptions.find(
            (item) => item.key === selectedUserId
          )}
          options={usersOptions}
          isClearable={true}
          isSearchable={true}
          // onChange={(item) => item ? dispatch(setSelectedUserId(item.key)) : false}
          onChange={(item) => {
            if (item) {
              dispatch(setUserOverlay(false));
              dispatch(setSelectedUserId(item.key));
            }
          }}
          className={classNames.input}
          styles={userAutoCompleteStyles}
          getOptionValue={(option) => option.label}
          menuIsOpen={true}
          inputId="user-search-input"
        />
        <FontAwesomeIcon
          style={{
            position: "absolute",
            top: 19,
            left: 5,
            color: "##2A2A2A",
            fontSize: 15,
          }}
          icon={faSearch}
        />
      </div>
    </Panel>
  );
};

export default SelectUserOverlay;
