import React, { useEffect, useState } from 'react';
import { mergeStyleSets } from '@fluentui/react/lib/Styling';
import { DAYS_OF_WEEK, CALENDAR_STEP_WIDTH } from '../../../utils/constants';
import { TooltipForText } from '..';

const DayLabel = ({ label, date, isDaysOfWeek = false, heightOfBackground, date: {isHoliday, holidayName} }) => {
    const [today, setToday] = useState(false);
    const [isWeekend, setIsWeekend] = useState(false);
    
    const classNames = mergeStyleSets({
        day: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            minHeight: 25,
            width: CALENDAR_STEP_WIDTH,
        },
        header: {
            zIndex: 100,
            width: CALENDAR_STEP_WIDTH,
            fontSize: 11,
            lineHeight: 24,
            minHeight: 25,
            textAlign: 'center',
        },
        holidayName: {
            display: 'inline',
            position: 'absolute',
            writingMode: 'vertical-lr',
            height: 'max-content',
            margin: '10px 0 0 5px',
            fontWeight: 'bold',
            fontSize: 13,
            color: '#868685',
            zIndex: 3,
        }
    });

    useEffect(() => {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        const labelDate = new Date(date['year'], date['month'], date['date']);
        if (labelDate.getTime() === today.getTime()) {
            setToday(true);
        }
        setIsWeekend(labelDate.getDay() % 6 === 0);
    }, [date]);

    let colorForLabel = '#000'; // black
    if (isWeekend) {
        colorForLabel = '#868685'; // gray
    }

    const labelStyles = () => {
        let styles = {};
        if (isWeekend) styles = {color: '#868685'};
        if (isHoliday) styles = {color: '#000', fontWeight: 'bold'};
        return styles
    }
    
    const containerStyles = () => {
        let styles = {
            position: 'absolute',
            left: 1,
            width: 20,
            zIndex: 2,
            color: '#fff',
        };
        if (today && !isDaysOfWeek) {
            styles = {
                ...styles,
                top: 1,
                height: 19,
                border: '2px solid #006CAD',
                borderTopLeftRadius: '5px',
                borderBottomLeftRadius: '5px',
                borderRight: 'none',
            };
        }
        if (today && isDaysOfWeek) {
            styles = {
                ...styles,
                top: 1,
                left: -2,
                width: 22,
                height: 19,
                border: '2px solid #006CAD',
                borderTop: '2px solid #006CAD',
                borderTopRightRadius: '5px',
                borderBottomRightRadius: '5px',
                borderLeft: 'none',
            };
        }
        if (isHoliday && !isDaysOfWeek) {
            styles = {
                ...styles,
                left: 2,
                top: 2,
                height: 19,
                background: '#A8C9DC',
            };
        }
        if (isHoliday && isDaysOfWeek) {
            styles = {
                ...styles,
                width: 25,
                left: -3,
                top: 2,
                height: 19,
                background: '#A8C9DC',
            };
        }
        return styles;
    };
    
    return (
        <div style={{ position: 'relative', minHeight: 24, width: CALENDAR_STEP_WIDTH }}>
            <div className={classNames.day}>
                <TooltipForText text={isHoliday ? holidayName : ''} noWidth zIndex={100}>
                    <div className={classNames.header} style={{ ...labelStyles() }}>
                        {isDaysOfWeek ? DAYS_OF_WEEK[new Date(date['year'], date['month'], date['date']).getDay()] : label}
                    </div>
                </TooltipForText>
            </div>
            <div style={{ ...containerStyles() }} />
            {isDaysOfWeek && isWeekend && heightOfBackground !== 0 && (
                <div style={{ position: 'absolute', top: 32, zIndex: 2, height: heightOfBackground, width: 24, backgroundColor: '#DBDBDB' }} />
            )}
        </div>
    );
};

export default DayLabel;
