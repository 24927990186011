import { MachinesActionTypes } from './machines.types';
import { workingRoleOptions } from '../../utils/constants';

const initialFilterForTechDepartment = {
    staffNames: '',
    availableRegion: null,
    selectedRegion: null,
    techArea: null,
    staffRole: workingRoleOptions.filter(item => item.key !== 3).map((item) => ({...item, isOn: true})),
}

const initialState = {
    filter: {
        techDepartment: null,
        staffRoleStaffPage: workingRoleOptions.map((item) => ({...item, isOn: true})),
        203: initialFilterForTechDepartment,
        202: initialFilterForTechDepartment,
        201: initialFilterForTechDepartment,
    },
    displayStaffOnPlanning: false,
    displayWeekView: false,
    selectedDayOnCalendar: new Date(),
    displayStaffVacation: true,
};

const machineReducer = (state = initialState, action) => {
    switch (action.type) {
        case MachinesActionTypes.CHANGE_FILTER:
            const currentTechDepartment = action.payload.techDepartment || state.filter.techDepartment;
            return {
                ...state,
                filter: {
                    ...state.filter,
                    techDepartment: currentTechDepartment,
                    staffRoleStaffPage:  action.payload.staffRoleStaffPage || state.filter.staffRoleStaffPage,
                    staffNames: typeof action.payload.staffNames !== 'undefined' ? action.payload.staffNames : state.filter.staffNames,
                    [currentTechDepartment]: {
                        ...state.filter[currentTechDepartment],
                        ...action.payload[currentTechDepartment],
                    }
                },
            };
        case MachinesActionTypes.TOGGLE_WEEK_VIEW:
            return {
                ...state,
                displayWeekView: action.payload
            };
        case MachinesActionTypes.SELECT_NEW_MONTH:
            return {
                ...state,
                selectedDayOnCalendar: action.payload
            };
        case MachinesActionTypes.TOGGLE_STAFF_VACATION:
            return {
                ...state,
                displayStaffVacation: action.payload || !state.displayStaffVacation,
            };
        default:
            return state;
    }
}

export default machineReducer;