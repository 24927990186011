export const appTheme = {
  palette: {
    themePrimary: "#006cad",
    themeLighterAlt: "#f2f8fc",
    themeLighter: "#cbe3f2",
    themeLight: "#a1cce7",
    themeTertiary: "#529fce",
    themeSecondary: "#1679b7",
    themeDarkAlt: "#00609c",
    themeDark: "#005184",
    themeDarker: "#003c61",
    neutralLighterAlt: "#ececec",
    neutralLighter: "#e8e8e8",
    neutralLight: "#dedede",
    neutralQuaternaryAlt: "#cfcfcf",
    neutralQuaternary: "#c6c6c6",
    neutralTertiaryAlt: "#bebebe",
    neutralTertiary: "#b6b6b6",
    neutralSecondary: "#9f9f9f",
    neutralPrimaryAlt: "#878787",
    neutralPrimary: "#2a2a2a",
    neutralDark: "#585858",
    black: "#404040",
    white: "#f1f1f1",
  },
};

export const defaultButtonStyles = {
  root: {
    backgroundColor: "#ffffff",
    borderRadius: 4,
    height: 40,
    borderColor: "#ffffff",
  },
  label: { fontWeight: 400 },
  rootHovered: {
    background: "#a1cce7",
    borderColor: "#a1cce7",
    color: "#ffffff",
  },
};

export const primaryButtonStyles = {
  root: { borderRadius: 4, height: 40 },
  label: { fontWeight: 400 },
  rootHovered: {},
};

export const outlinedButtonStyles = {
  root: {
    borderRadius: 4,
    height: 40,
    borderColor: "#006CAD",
    color: "#006CAD",
  },
  label: { fontWeight: 400 },
  rootHovered: { borderColor: "#006CAD" },
};

export const inputStyles = {
  fieldGroup: {
    height: 48,
    borderRadius: 4,
    borderColor: "rgb(159, 159, 159)",
  },
  field: {
    fontSize: "14px",
    paddingTop: "13px",
  },
  subComponentStyles: {
    label: {
      root: {
        fontSize: "11px",
        position: "absolute",
        zIndex: 1,
        transform: "translate(9px, -1px) scale(1)",
      },
    },
  },
};

export const dropDownStyles = {
  dropdownItem: {
    height: 48,
  },
  dropdownItemSelected: {
    height: 48,
  },
  caretDown: {
    marginTop: 8,
    marginRight: 14,
  },
  title: {
    height: 48,
    lineHeight: 48,
    borderRadius: 4,
    borderColor: "rgb(159, 159, 159)",
    paddingTop: "7px",
  },
  dropdown: {
    width: 320,
    caretDownWrapper: {
      marginTop: 8,
    },
  },
  subComponentStyles: {
    label: {
      root: {
        fontSize: "11px",
        position: "absolute",
        zIndex: 1,
        transform: "translate(9px, -1px) scale(1)",
      },
    },
  },
};

export const outlinedDropDownStyles = {
  dropdownItem: {
    height: 40,
  },
  dropdownItemSelected: {
    height: 40,
  },
  caretDown: {
    marginTop: 5,
    marginRight: 10,
    color: "#006CAD",
  },
  title: {
    height: 40,
    lineHeight: 40,
    borderRadius: 4,
    borderColor: "#006CAD",
    color: "#006CAD",
  },
  dropdown: {
    width: 108,
  },
};

export const customAutoCompleteStyles = (error, whiteBg = false) => ({
  input: (provided) => ({
    ...provided,
    height: 38,
    cursor: "none",
    paddingTop: 17,
    color: "black",
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    paddingRight: 9,
  }),
  control: (provided) => ({
    ...provided,
    background: whiteBg ? "#fff" : "#F1F1F1",
    border: "1px solid rgb(159, 159, 159)",
    boxShadow: "none",
    "&:hover": {
      borderColor: error ? "rgb(163,38,44)" : "rgb(42, 42, 42)",
      boxShadow: error ? "0 0 0 1px rgb(163,38,44)" : "0 0 0 1px #2684ff",
    },
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "rgb(159, 159, 159)",
    marginTop: 8,
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 3,
    position: "relative",
    color: "black",
  }),
  singleValue: (provided) => ({
    ...provided,
    paddingTop: 17,
  }),
  multiValue: (provided) => ({
    ...provided,
    paddingTop: 15,
  }),
});

export const userAutoCompleteStyles = {
  valueContainer: (provided) => ({
    ...provided,
    // paddingLeft: 15,
    marginLeft: 20,
  }),
  input: (provided) => ({
    ...provided,
    height: 30,
    width: 100,
    cursor: "none",
    color: "black",
    paddingTop: 5,
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    paddingRight: 9,
    display: "none",
  }),
  control: (provided) => ({
    ...provided,
    background: "transparent",
    border: "1px solid transparent",
    height: 50,
    borderRadius: 0,
    borderBottom: "1px solid  rgb(159, 159, 159)",
    boxShadow: "none",
    "&:hover": {
      borderColor: "transparent",
      boxShadow: "none",
    },
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "rgb(159, 159, 159)",
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 3,
    position: "relative",
    color: "black",
    background: "transparent",
    boxShadow: "none",
  }),
  menuList: (provided) => ({
    maxHeight: "80vh",
  }),
  singleValue: (provided) => ({
    ...provided,
  }),
  multiValue: (provided) => ({
    ...provided,
  }),
  option: (provided) => ({
    ...provided,
    padding: "15px 0",
  }),
};

export const autoCompleteSearchStyles = {
  input: (provided) => ({
    ...provided,
    height: 38,
    cursor: "none",
    paddingTop: 10,
  }),
  option: (provided, data) => {
    if (data.label === "Clear previous search")
      return {
        ...provided,
        color: "#006CAD",
        textAlign: "center",
        cursor: "pointer",
      };
    return provided;
  },
  indicatorsContainer: (provided) => ({
    ...provided,
    paddingRight: 9,
  }),
  control: (provided) => ({
    ...provided,
    height: 47,
    background: "#fff",
    border: "none",
    borderRadius: "0%",
    borderBottom: "1px solid rgb(159, 159, 159)",
    boxShadow: "none",
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "rgb(159, 159, 159)",
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 3,
    position: "relative",
  }),
};

export const headerRightButtonStyles = (pageWidth, scrollY) => ({
  position: "fixed",
  top: 150 - scrollY,
  right: pageWidth > 1000 ? 120 : 20,
});
