import { mergeStyleSets } from '@fluentui/merge-styles';
import { Image } from '@fluentui/react';
import { faDigging, faUserHardHat, faUserTie } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState, Fragment } from 'react';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { getStaffs } from '../../../../redux/user/user.actions';
import { checkTimeOverlap } from '../../../../utils/utils';

const classNames = mergeStyleSets({
    tableColumn: {
        padding: '5px',
        fontWeight: 'bold',
        overflow: 'hidden',
    },
    titleElementStyle: {
        display: 'flex',
    },
    titleNameElementStyle: {
        fontWeight: 'bold',
        margin: '5px 0px 0px 10px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        width: 180,
    },
    text: {
        padding: '0 10px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        minWidth: '85px',
        color: '#000',
    },
    contact: {
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        maxHeight: 0,
        transition: 'max-height 0.5s ease-out',
    },
    contactInfo: {
        fontWeight: 'normal',
        margin: '5px 0px 10px 15px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        width: '65vw',
        textTransform: 'none',
    },
    phoneAndEmail: {
        textDecoration: 'none',
        color: '#006CAD',
    },
});

const icons = {
    driver: faUserHardHat,
    worker: faDigging,
    manager: faUserTie,
};

const PlanReview = ({ data }) => {
    const dispatch = useDispatch();
    const [planContent, setPlanContent] = useState({});
    const { data: staffsData } = useQuery('staffs', () => dispatch(getStaffs()));

    useEffect(() => {
        if (staffsData) {
            const planContentTemp = {
                machine: {},
                worker: {},
                manager: {},
            };
            // Get all machines
            data.machines.forEach((machine) => {
                if (!planContentTemp.machine[machine.machineName]) planContentTemp.machine[machine.machineName] = { period: [], driver: {} };
                planContentTemp.machine[machine.machineName].period.push({
                    start: machine.start,
                    end: machine.end,
                });
            });

            // Get all staff
            const staffKey = ['workers', 'managers'];
            staffKey.forEach((key) => {
                data[key].forEach((staff) => {
                    // const start = new Date(staff.start.slice(0, -1));
                    // const end = new Date(staff.end.slice(0, -1));
                    // const hasPlanToday = checkTimeOverlap(start, end, new Date(), new Date(), false);

                    // if (hasPlanToday) {
                        const staffData = staffsData.find((item) => item.userId === staff.id);
                        const staffKey = `${staff.firstName} ${staff.lastName}`;
                        if (!planContentTemp[key.slice(0, -1)][staffKey]) planContentTemp[key.slice(0, -1)][staffKey] = [];
                        planContentTemp[key.slice(0, -1)][staffKey].push({
                            id: staff.id,
                            start: staff.start,
                            end: staff.end,
                            businessPhones: staffData?.businessPhones || 'Unknown',
                            email: staffData?.email || 'Unknown',
                        });
                    // }
                });
            });

            // Append driver to machine
            Object.keys(planContentTemp.machine).forEach((machineName) => {
                data.drivers.forEach((driver) => {
                    const start = new Date(driver.start.slice(0, -1));
                    const end = new Date(driver.end.slice(0, -1));
                    // const hasPlanToday = checkTimeOverlap(start, end, new Date(), new Date(), false);
                    if ((driver.machineName || driver.machineId) === machineName 
                    // && hasPlanToday
                    ) {
                        const staffData = staffsData.find((item) => item.userId === driver.id);
                        if (!planContentTemp.machine[machineName].driver) planContentTemp.machine[machineName].driver = [];
                        const driverFullName = `${driver.firstName} ${driver.lastName}`;
                        if (!planContentTemp.machine[machineName].driver[driverFullName]) planContentTemp.machine[machineName].driver[driverFullName] = [];
                        planContentTemp.machine[machineName].driver[driverFullName].push({
                            id: driver.id,
                            name: driverFullName,
                            start: driver.start,
                            end: driver.end,
                            businessPhones: staffData?.businessPhones || 'Unknown',
                            email: staffData?.email || 'Unknown',
                        });
                    }
                });
            });

            setPlanContent(planContentTemp);
        }
    }, [data, staffsData]);

    const toggleContent = (elementClass) => {
        const collapsibleElements = document.querySelectorAll(`.collapsible`);
        for (let i = 0; i < collapsibleElements.length; i++) {
            const classlist = collapsibleElements[i].classList;
            if (classlist.contains(elementClass) && !collapsibleElements[i].style.maxHeight) {
                collapsibleElements[i].style.maxHeight = '100px';
            } else {
                collapsibleElements[i].style.maxHeight = null;
            }
        }
    };

    return (
        <div style={{ backgroundColor: '#fff', borderRadius: 10, marginTop: 20, paddingBottom: 1 }}>
            <div style={{ height: 10 }} />
            <table
                style={{
                    borderCollapse: 'collapse',
                    margin: '5px 0 20px 10px',
                    overFlow: 'scroll',
                    tableLayout: 'fixed',
                    fontSize: 12,
                    textTransform: 'uppercase',
                }}
            >
                {Object.keys(planContent).map((key) =>
                    Object.keys(planContent[key]).length
                        ? Object.keys(planContent[key]).map((name, mainIndex) =>
                            key === 'machine' ? (
                                <tbody key={`main-${name}-${mainIndex}-${Math.random()}`}>
                                    {/* Machine */}
                                    <tr>
                                        <td className={classNames.tableColumn} style={{ verticalAlign: 'top' }}>
                                            <div className={classNames.titleElementStyle}>
                                                <Image
                                                    style={{ marginTop: -5 }}
                                                    src={`${window.location.origin}/img/machineBlack.svg`}
                                                    alt='machine'
                                                    width={20}
                                                    height={32}
                                                    className={classNames.image}
                                                />
                                                <div className={classNames.titleNameElementStyle}>{name}</div>
                                            </div>
                                        </td>
                                    </tr>

                                    {/* Driver */}
                                    {Object.keys(planContent[key][name].driver).map((driverName, index) => {
                                        const phone = planContent[key][name].driver[driverName][0].businessPhones;
                                        const email = planContent[key][name].driver[driverName][0].email;
                                        return (
                                            <Fragment key={`${driverName}-${index}`}>
                                                <tr>
                                                    <td className={classNames.tableColumn} style={{ verticalAlign: 'top' }}>
                                                        <div className={classNames.titleElementStyle} style={{ marginLeft: 30 }}>
                                                            <FontAwesomeIcon
                                                                icon={icons['driver']}
                                                                style={{ fontSize: 19, marginTop: 5, width: '1.125em' }}
                                                            />
                                                            <div
                                                                className={classNames.titleNameElementStyle}
                                                                onClick={() => toggleContent(`collapsible-${driverName.replace(/\s/g, '')}`)}
                                                            >
                                                                {driverName}
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className={classNames.tableColumn} style={{ verticalAlign: 'top' }}>
                                                        <div
                                                            className={`collapsible collapsible-${driverName.replace(/\s/g, '')} ${classNames.contact}`}
                                                            style={{ marginLeft: 48 }}
                                                        >
                                                            <div className={classNames.contactInfo}>
                                                                Mobil:{' '} &emsp;
                                                                <a className={classNames.phoneAndEmail} href={phone !== 'Unknown' ? `tel:${phone}` : null}>
                                                                    {phone}
                                                                </a>
                                                            </div>
                                                            <div className={classNames.contactInfo}>
                                                                Email:{' '} &emsp;
                                                                <a className={classNames.phoneAndEmail} href={email !== 'Unknown' ? `mailto:${email}` : null}>
                                                                    {email}
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </Fragment>
                                        );
                                    })}
                                </tbody>
                            ) : (
                                <tbody key={`${name}-${mainIndex}`}>
                                    <tr>
                                        <td className={classNames.tableColumn} style={{ verticalAlign: 'top' }}>
                                            <div className={classNames.titleElementStyle}>
                                                <FontAwesomeIcon icon={icons[key]} style={{ fontSize: 19, marginTop: 5, width: '1.125em' }} />
                                                <div
                                                    className={classNames.titleNameElementStyle}
                                                    onClick={() => toggleContent(`collapsible-${name.replace(/\s/g, '')}`)}
                                                >
                                                    {name}
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className={classNames.tableColumn} style={{ verticalAlign: 'top' }}>
                                            <div className={`collapsible collapsible-${name.replace(/\s/g, '')} ${classNames.contact}`} style={{marginLeft: 18}}>
                                                <div className={classNames.contactInfo}>
                                                    Mobil:{' '} &emsp;
                                                    <a
                                                        className={classNames.phoneAndEmail}
                                                        href={
                                                            planContent[key][name][0].businessPhones !== 'Unknown'
                                                                ? `tel:${planContent[key][name][0].businessPhones}`
                                                                : null
                                                        }
                                                    >
                                                        {planContent[key][name][0].businessPhones}
                                                    </a>
                                                </div>
                                                <div className={classNames.contactInfo}>
                                                    Email:{' '} &emsp;
                                                    <a
                                                        className={classNames.phoneAndEmail}
                                                        href={planContent[key][name][0].email !== 'Unknown' ? `mailto:${planContent[key][name][0].email}` : null}
                                                    >
                                                        {planContent[key][name][0].email}
                                                    </a>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            )
                        )
                        : null
                )}
            </table>
        </div>
    );
};

export default PlanReview;
