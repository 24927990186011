import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useQuery, useQueryClient } from "react-query";
import { getProject } from "../../../../redux/project/project.actions";
import {
  getPlan,
  clearPlanOverlayProjectId,
} from "../../../../redux/plan/plan.actions";
import { mergeStyleSets } from "@fluentui/react/lib/Styling";
import { Panel } from "@fluentui/react";
import ProjectDetails from "./ProjectDetails";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faTimes } from "@fortawesome/pro-regular-svg-icons";
import PictureAndCommentSection from "./PictureAndCommentSection";
import ScreenSizeAndPosition from "../../../common/ScreenSizeAndPosition";
import PlanReview from "./PlanReview";

const classNames = mergeStyleSets({
  buttonBottomContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
  },
  closeButtonOutside: {
    position: "sticky",
    top: 0,
    left: 12,
    cursor: "pointer",
    background: "#DFDFDF",
    width: "100%",
    zIndex: 100,
    marginBottom: 5,
  },
});

const overlayStyle = {
  main: {
    width: "100%",
    background: "#DFDFDF",
    paddingTop: 0,
  },
};

const PlanOverlay = ({
  getProject,
  getPlan,
  overlayPlanIndex,
  clearPlanOverlayProjectId,
  overlayProjectId,
}) => {
  const queryClient = useQueryClient();

  const {
    data: projectData,
    refetch: refetchProject,
    isSuccess: projectIsSuccess,
  } = useQuery(
    ["project", overlayProjectId],
    () => getProject(overlayProjectId),
    {
      enabled: !!overlayProjectId,
    }
  );
  const { data: planData, isSuccess: planIsSuccess } = useQuery(
    ["plan", overlayProjectId, overlayPlanIndex],
    () => getPlan(overlayProjectId),
    {
      enabled: !!overlayProjectId,
      initialData: () =>
        queryClient
          .getQueryData("plans")
          ?.find((plan) => plan.projectId === overlayProjectId),
    }
  );

  return (
    <Panel
      // To entirely disable the default dismiss behavior:
      // 1. Don't provide an onDismiss prop
      isOpen={!!overlayProjectId} // 2. Control the visibility
      hasCloseButton={false}
      customWidth="500px"
      styles={overlayStyle}
    >
      <div
        onClick={clearPlanOverlayProjectId}
        className={classNames.closeButtonOutside}
      >
        <FontAwesomeIcon
          icon={faArrowLeft}
          style={{
            color: projectIsSuccess && `rgb(${projectData["color"]})`,
            fontSize: 20,
            position: "relative",
          }}
        />
      </div>
      <div className={classNames.innerContainer}>
        {projectIsSuccess && projectData && (
          <Fragment>
            <ProjectDetails
              refetchProject={refetchProject}
              projectData={projectData}
              planData={planData}
              overlayPlanIndex={overlayPlanIndex}
              clearPlanOverlayProjectId={clearPlanOverlayProjectId}
            />
            {planIsSuccess && planData && (
              <PlanReview
                data={planData?.machineRequirements[overlayPlanIndex]}
              />
            )}
          </Fragment>
        )}
      </div>
    </Panel>
  );
};

const mapStateToProps = (state) => ({
  overlayProjectId: state.plan.overlayProjectId,
  overlayPlanIndex: state.plan.overlayPlanIndex,
});

export default connect(mapStateToProps, {
  getProject,
  getPlan,
  clearPlanOverlayProjectId,
})(PlanOverlay);
