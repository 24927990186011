export const toggleSelectorItem = (selectedTechAreas, itemToToggle) => {
    if (selectedTechAreas.includes(itemToToggle)) {
        return selectedTechAreas.filter(selectedTechArea => selectedTechArea !== itemToToggle);
    } else {
        return [...selectedTechAreas, itemToToggle];
    }
};

export const getMachineDocumentLink = (machineNumber) => {
    return `https://aarsleff.sharepoint.com/teams/FS/SitePages/${machineNumber.toLowerCase().replace(/ /g, '-')}.aspx`
}