import { useAccount, useMsal } from "@azure/msal-react";
import { Calendar, DateRangeType, Image, ImageFit } from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import { DirectionalHint, Stack } from "@fluentui/react";
import { mergeStyleSets } from "@fluentui/react/lib/Styling";
import {
  faBarsStaggered,
  faCalendarAlt,
  faSignOut,
  faUmbrellaBeach,
  faUserTie,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment, useEffect } from "react";
import { useMutation } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { postNewFilterSetting } from "../../../redux/settingManagement/settingManagement.action";
import { logout, updateSettingFilter } from "../../../redux/user/user.actions";
import { getMonday, modifyCustomFilters } from "../../../utils/utils";
import DeleteConfirmation from "../DeleteConfirmation";
import FixPositionBubble from "../FixPositionBubble";
import ToggleButtonPill from "../ToggleButtonPill";
import { divButtonStyle } from "./ButtonStyles";
import WeekCalendar from "./WeekCalendar";
import { isProdURL } from "../../../utils/constants";

const iconStyle = {
  marginRight: 2,
  fontSize: 13,
};

const classNames = mergeStyleSets({
  controls: {
    marginTop: 30,
    marginRight: 20,
    display: "flex",
    justifyContent: "flex-end",
    zIndex: 1002,
  },
  newPrebookingSelection: {
    display: "flex",
    alignItems: "center",
    fontSize: 12,
    width: 85,
    height: 32,
    fontFamily: "Verdana",
    cursor: "pointer",
    marginLeft: -5,
    selectors: {
      ":hover": {
        backgroundColor: "#99b3ff",
        borderRadius: 2,
      },
    },
  },
  unSelectedIcon: {
    ...iconStyle,
    color: "#006CAD",
  },
  selectedIcon: {
    ...iconStyle,
    color: "#fff",
  },
  userName: {
    fontSize: 16,
    fontWeight: "bold",
    marginTop: 10,
  },
  signOut: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    position: "relative",
    cursor: "pointer",
    color: "#006CAD",
    fontSize: 24,
    marginTop: 20,
    width: "100%",
  },
});

const CalendarControlsRight = ({
  onClickChooseDate,
  isFormandDisponentFunktioner,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  const { displayStaffVacation } = useSelector((state) => state.machine);
  const { defaultFilter } = user;
  const [showUserPopup, { toggle: toggleTeachingShowUserPopup }] =
    useBoolean(false);
  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [logoutConfirmation, { toggle: toggleLogoutConfirmation }] =
    useBoolean(false);
  const [
    teachingBubbleShowChooseMonth,
    { toggle: toggleTeachingShowChooseMonth },
  ] = useBoolean(false);
  const calendarStartFrom =
    useSelector((state) => state.user.user.defaultFilter.customFilters)?.find(
      (item) => item.key === "calendarStartFrom"
    )?.text || "month";
  const { selectedDayOnCalendar } = useSelector((state) => state.machine);

  const { mutate: mutateNewFilterSetting } = useMutation(
    (settingData) => dispatch(postNewFilterSetting(settingData, false)),
    {
      onSuccess: (data, settingData) => {
        if (data) {
          dispatch(updateSettingFilter(settingData));
        }
      },
    }
  );

  useEffect(() => {
    try {
      if (!account && inProgress === "none") {
        logout();
      }
    } catch (err) {
      console.log(err);
    }
  }, [account, inProgress, instance, logout]);

  const handleTodayClick = () => {
    const monday = getMonday(new Date());
    onClickChooseDate(monday);
  };

  useEffect(() => {
    if (teachingBubbleShowChooseMonth) {
      // wait for calendar to be visible
      setTimeout(() => {
        const goTodayButton = document.getElementsByClassName("js-goToday");
        for (let i = 0; i < goTodayButton.length; i++) {
          goTodayButton[i].textContent = "Go to current month";
          goTodayButton[i].addEventListener("click", handleTodayClick);
        }
      }, 50);
    }
  }, [teachingBubbleShowChooseMonth]);

  const onLogout = async () => {
    try {
      dispatch(logout());
      await instance.logoutRedirect({ postLogoutRedirectUri: "/login" });
    } catch (error) {
      console.log(error);
    }
  };

  const changeCalendarStartFrom = (value) => {
    const newSettings = modifyCustomFilters(
      defaultFilter,
      "calendarStartFrom",
      value
    );
    mutateNewFilterSetting(newSettings);
  };

  const currentPage = window.location.href.split("/").pop();

  const goToMainApp = () => {
    window.location.href = isProdURL()
      ? "https://fleetplanner.aarsleff.com/"
      : "https://fleetplanner-fe.aarsleff.com/";
  };

  return (
    <Fragment>
      <div className={classNames.controls}>
        <Stack horizontal wrap tokens={{ childrenGap: 30 }}>
          <Stack horizontal style={{ marginRight: 0 }}>
            {isFormandDisponentFunktioner && (
              <div style={divButtonStyle(false)} onClick={goToMainApp}>
                <FontAwesomeIcon
                  icon={faBarsStaggered}
                  className={classNames.unSelectedIcon}
                />
              </div>
            )}
            <div
              style={divButtonStyle(currentPage)}
              onClick={() =>
                history.push(!currentPage ? "/vacation-view" : "/")
              }
            >
              <FontAwesomeIcon
                icon={faUmbrellaBeach}
                className={
                  !currentPage
                    ? classNames.unSelectedIcon
                    : classNames.selectedIcon
                }
              />
            </div>
            <div
              style={divButtonStyle(false)}
              onClick={() => history.push("/machine-view")}
            >
              <Image
                src="./img/machineBlue.svg"
                imageFit={ImageFit.contain}
                alt="Machine"
                width={18}
                height={18}
              />
            </div>
            <div
              id="chooseMonth"
              style={divButtonStyle(false)}
              onClick={toggleTeachingShowChooseMonth}
            >
              <FontAwesomeIcon
                icon={faCalendarAlt}
                className={classNames.unSelectedIcon}
              />
            </div>
            {/* <div id='userIcon' style={divButtonStyle(false)} onClick={toggleTeachingShowUserPopup}>
                            <FontAwesomeIcon icon={faUserTie} className={classNames.unSelectedIcon} />
                        </div> */}
          </Stack>
        </Stack>

        {/* Choose month bubble */}
        {teachingBubbleShowChooseMonth && (
          <FixPositionBubble
            target="#chooseMonth"
            onDismiss={toggleTeachingShowChooseMonth}
            calloutProps={{
              directionalHint: DirectionalHint.bottomCenter,
              calloutWidth: calendarStartFrom === "month" ? 270 : 400,
            }}
          >
            {calendarStartFrom === "month" ? (
              <Calendar
                dateRangeType={DateRangeType.Month}
                value={selectedDayOnCalendar}
                showGoToToday
                highlightSelectedMonth
                isDayPickerVisible={false}
                onSelectDate={onClickChooseDate}
              />
            ) : (
              <WeekCalendar onClickChooseDate={onClickChooseDate} />
            )}
            <div>
              {/* <div className={classNames.avatarOverlay}></div>
                        <Persona
                            className={classNames.avatar}
                            onClick={() => {
                                history.push('/notification-management');
                            }}
                            imageUrl={user.imageUrl ? `${SOURCE_API}${user.imageUrl}` : ''}
                            size={PersonaSize.size72}
                        /> */}
              {/* <div className={classNames.userName}>
                {user.firstName} {user.lastName}
              </div> */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: 20,
                }}
              >
                {/* <div className={classNames.title} style={{ marginBottom: 10 }}>
                  Calendar starting point
                </div> */}
                <ToggleButtonPill
                  toggle={calendarStartFrom === "week"}
                  onClick={() =>
                    changeCalendarStartFrom(
                      calendarStartFrom === "month" ? "week" : "month"
                    )
                  }
                  leftText="Month"
                  rightText="Week"
                />
              </div>
              <div className={classNames.signOut} onClick={onLogout}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <FontAwesomeIcon
                    id="logout"
                    className={classNames.unSelectedIcon}
                    icon={faSignOut}
                  />
                </div>
              </div>
              {/* {logoutConfirmation && (
                            <DeleteConfirmation
                                label='Are you sure you want to logout?'
                                target={`#logout`}
                                toggleTeaching={toggleLogoutConfirmation}
                                onClickConfirm={onLogout}
                            />
                        )} */}
            </div>
          </FixPositionBubble>
        )}
        {showUserPopup && (
          <FixPositionBubble
            target={"#userIcon"}
            onDismiss={toggleTeachingShowUserPopup}
            calloutProps={{
              directionalHint: DirectionalHint.bottomCenter,
              calloutWidth: 250,
              calloutheight: 50,
            }}
          >
            <div>
              {/* <div className={classNames.avatarOverlay}></div>
                        <Persona
                            className={classNames.avatar}
                            onClick={() => {
                                history.push('/notification-management');
                            }}
                            imageUrl={user.imageUrl ? `${SOURCE_API}${user.imageUrl}` : ''}
                            size={PersonaSize.size72}
                        /> */}
              <div className={classNames.userName}>
                {user.firstName} {user.lastName}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: 20,
                }}
              >
                <div className={classNames.title} style={{ marginBottom: 10 }}>
                  Calendar starting point
                </div>
                <ToggleButtonPill
                  toggle={calendarStartFrom === "week"}
                  onClick={() =>
                    changeCalendarStartFrom(
                      calendarStartFrom === "month" ? "week" : "month"
                    )
                  }
                  leftText="Month"
                  rightText="Week"
                />
              </div>
              <div className={classNames.signOut} onClick={onLogout}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <FontAwesomeIcon
                    id="logout"
                    className={classNames.unSelectedIcon}
                    icon={faSignOut}
                  />
                </div>
              </div>
              {/* {logoutConfirmation && (
                            <DeleteConfirmation
                                label='Are you sure you want to logout?'
                                target={`#logout`}
                                toggleTeaching={toggleLogoutConfirmation}
                                onClickConfirm={onLogout}
                            />
                        )} */}
            </div>
          </FixPositionBubble>
        )}
        {logoutConfirmation && (
          <DeleteConfirmation
            label="Are you sure you want to logout?"
            target={`#logout`}
            toggleTeaching={toggleLogoutConfirmation}
            onClickConfirm={onLogout}
          />
        )}
      </div>
    </Fragment>
  );
};

export default CalendarControlsRight;
