import { AppActionTypes } from './app.types';

const initialState = {
    showSidebar: true,
    barDrawer: '',
    vacationReason: null,
    highlightedStaffId: null,
};

const appReducer = (state = initialState, action) => {
    switch (action.type) {

        case AppActionTypes.SET_SIDE_BAR_STATUS:
            return {
                ...state,
                showSidebar: action.value, 
            }
        
        case AppActionTypes.CHANGE_ZOOM_RATIO:
            return {
                ...state,
                ...action.value, 
            }

        case AppActionTypes.SET_BAR_DRAWER:
            return {
                ...state,
                barDrawer: action.payload.barDrawer,
                vacationReason: action.payload.vacationReason,
            };
        
        case AppActionTypes.SET_HIGHLIGHT_STAFF:
            return {
                ...state,
                highlightedStaffId: action.value,
            }
        
        default:
            return state;
    }
}

export default appReducer;