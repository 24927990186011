import { mergeStyleSets } from "@fluentui/react/lib/Styling";
import moment from "moment";
import "moment/locale/da";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Rnd } from "react-rnd";
import { setPlanOverlayProjectId } from "../../../redux/plan/plan.actions";
import {
  BAR_WIDTH,
  CALENDAR_STEP_HEIGHT,
  CALENDAR_STEP_WIDTH,
  CALENDAR_WEEKVIEW_STEP_WIDTH,
} from "../../../utils/constants";
import { getPeriodDuration, lighterDarkerColor } from "../../../utils/utils";

const StaffPlan = ({
  staff,
  staff: {
    id,
    start,
    end,
    projectId,
    projectName,
    hovedsagsNummer,
    indexMachineRequirement,
    color,
    isHidden,
    machines,
  },
  verticalPositions,
  calendarStart,
  calendarEnd,
  counter,
}) => {

  if(isHidden){
    projectName="Reservation/Tilbud"
  }

  const dispatch = useDispatch();
  const calendarState = JSON.stringify(useSelector((state) => state.machine));
  const { displayWeekView } = useSelector((state) => state.machine);
  const calendarStepWidth = displayWeekView
    ? CALENDAR_WEEKVIEW_STEP_WIDTH
    : CALENDAR_STEP_WIDTH;
  const width = BAR_WIDTH;
  const [x, setX] = useState(0);
  const [y, setY] = useState(0);
  const [height, setHeight] = useState(0);
  const [display, setDisplay] = useState(false);
  const colorCode = color;
  let lighterColorCode = isHidden ? lighterDarkerColor(color, 10) : colorCode;
  let bgColor = `repeating-linear-gradient(90deg, rgb(${colorCode}), rgb(${colorCode}) 24px, rgb(${lighterColorCode}) 24px, rgb(${lighterColorCode}) 48px)`;

  let calendarStepHeight = CALENDAR_STEP_HEIGHT;
  const ref = useRef(null);
  const renderDependencies =
    calendarState + JSON.stringify(verticalPositions) + JSON.stringify(staff);
  if (!ref.current) ref.current = renderDependencies;
  const shouldComponentRerender = ref.current !== renderDependencies;

  const classNames = mergeStyleSets({
    icon: {
      color: "#fff",
      marginRight: 2,
      fontSize: 13,
      fontWeight: "lighter",
      selectors: {
        ":hover": {
          color: "#DBDBDB",
        },
      },
      cursor: "pointer",
    },
    draggable: {
      position: "relative",
      top: "50%",
      transform: "translateY(-50%)",
    },
    barContent: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      width: "calc(100% - 15px)",
      height: "100%",
      cursor: "pointer",
    },
  });

  useEffect(() => {
    if ((!x && !y) || shouldComponentRerender) {
      if (shouldComponentRerender) ref.current = renderDependencies;

      const keyStart = moment(start).utcOffset(0).format("YYYY-M-D");
      const keyEnd = moment(end).add(1, "d").utcOffset(0).format("YYYY-M-D");

      let xPosition = counter;
      let yPosition = verticalPositions[keyStart];
      let endValue = verticalPositions[keyEnd];

      if (new Date(start.slice(0, -1))?.getTime() <= calendarStart?.getTime()) {
        yPosition = 0;
      }

      if (new Date(end.slice(0, -1))?.getTime() >= calendarEnd?.getTime()) {
        endValue = verticalPositions["end"];
      }
      setY(yPosition * calendarStepHeight);

      if (typeof yPosition !== "undefined") {
        setX(xPosition * (BAR_WIDTH + 20));
        setHeight((endValue - yPosition) * calendarStepHeight);
        setDisplay(true);
      } else {
        setDisplay(false);
      }
    }
  }, [verticalPositions, verticalPositions, calendarStart, calendarEnd, staff]);

  let content = (
    <div
      id={`staffPlan-${id}-${indexMachineRequirement}`}
      className={`'dragDisabled' ${classNames.barContent}`}
      style={{ marginLeft: 10, justifyContent: "flex-start" }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            alignItems: "flex-end",
            marginTop: 4,
          }}
        >
          <span
            style={{
              color: "#fff",
              fontWeight: "bold",
              fontSize: 11,
              marginRight: 8,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {projectName}
          </span>
          <span
            style={{
              color: "#fff",
              fontSize: 11,
              marginRight: 8,
            }}
          >
            {!isHidden && (hovedsagsNummer || "Unknown number")}
          </span>
        </div>
        {
          !isHidden && 
          <div style={{ marginTop: 10 }}>
            {machines
              .slice(0, height / calendarStepHeight - 1)
              .map((machine, index) => (
                <div
                  style={{
                    color: "#fff",
                    textTransform: "uppercase",
                    fontSize: 11,
                  }}
                  key={`${machine.machineId}-${index}`}
                >
                  {machine.machineName}
                </div>
              ))}
          </div>
        }
      </div>
    </div>
  );

  return (
    display &&
    height > 0 && (
      <Fragment>
        <Rnd
          style={{ zIndex: 3, top: 0, left: 0, display: "absolute" }}
          // default={{ x: x, y: y, width: width, height: 48}}
          size={{ width: width, height: height }}
          position={{ x: x, y: y }}
          enableResizing={{ left: false, right: false }}
          resizeGrid={[calendarStepWidth, calendarStepHeight]}
          dragGrid={[calendarStepWidth, calendarStepHeight]}
          dragAxis={"none"}
          bounds=".planContainer"
          dragHandleClassName="dragHandle"
        >
          <div
            id={`staffPlan-${id}-${projectId}-${indexMachineRequirement}`}
            className={classNames.draggable}
            style={{
              backgroundImage: bgColor,
              backgroundSize: "48px 48px",
              backgroundPosition: "0 0, 0 0, -24px -24px, 24px 24px",
              display: "flex",
              height: height,
              borderRadius: 4,
            }}
            onClick={() => {
              if (!isHidden) {
                return dispatch(
                  setPlanOverlayProjectId(projectId, indexMachineRequirement)
                );
              }
            }}
          >
            {content}
          </div>
        </Rnd>
      </Fragment>
    )
  );
};

export default StaffPlan;
