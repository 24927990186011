import { useAccount, useMsal } from '@azure/msal-react';
import { DirectionalHint } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { Persona, PersonaSize } from '@fluentui/react/lib/Persona';
import { mergeStyleSets } from '@fluentui/react/lib/Styling';
import { faCircleUser, faSignOut } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { postNewFilterSetting } from '../../../redux/settingManagement/settingManagement.action';
import { logout, updateSettingFilter } from '../../../redux/user/user.actions';
import { SOURCE_API } from '../../../utils/constants';
import { modifyCustomFilters } from '../../../utils/utils';
import FixPositionBubble from '../FixPositionBubble';
import ToggleButtonPill from '../ToggleButtonPill';

const classNames = mergeStyleSets({
    header: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        width: 'calc(100% - 3360px)',
        height: 72,
        margin: '40px 0',
    },
    innerContainer: {
        position: 'fixed',
        display: 'flex',
        alignItems: 'center',
    },
    avatar: {
        width: 40,
        borderRadius: '50%',
        margin: '-35px auto 0 auto',
        boxSizing: 'unset',
        border: '3px solid white',
    },
    userName: {
        textAlign: 'center',
        fontSize: 16,
        fontWeight: 'bold',
        marginTop: 10,
    },
    title: {
        fontWeight: 700,
        fontSize: 11,
    },
    subTitle: {
        fontSize: '11px',
        lineHeight: '11px',
    },
    icon: {
        fontSize: 40,
        color: '#006CAD',
        cursor: 'pointer',
        borderRadius: '50%',
        selectors: {
            ':hover': {
                borderRadius: '50%',
                boxShadow: '0px 0px 30px #006CAD',
                transition: 'all 0.2s ease-in',
            },
        },
    },
    iconNumber: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        borderRadius: '50%',
        marginTop: '-38px',
        marginLeft: '15px',
        color: '#FFFFFF',
        fontSize: 12,
        height: 24,
        width: 24,
        backgroundColor: '#006CAD',
    },
    avatarOverlay: {
        height: 100,
        margin: '-25px -25px 0 -25px',
        background: '#a6d2ff',
    },
    signOut: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        position: 'relative',
        cursor: 'pointer',
        color: '#006CAD',
        fontSize: 24,
        height: 50,
        width: '100%',
    },
});

const CalendarHeaderRight = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user.user);
    const { defaultFilter } = useSelector((state) => state.user.user);
    const realPageWidth = document.documentElement.offsetWidth;
    const [scrollPosition, setScrollPosition] = useState(0);
    const [calendarStartFrom, setCalendarStartFrom] = useState('month');
    const [showUserPopup, { toggle: toggleTeachingShowUserPopup }] = useBoolean(false);
    // const [logoutConfirmation, { toggle: toggleLogoutConfirmation }] = useBoolean(false);
    const { instance, accounts, inProgress } = useMsal();
    const account = useAccount(accounts[0] || {});

    useEffect(() => {
        try {
            if (!account && inProgress === 'none') {
                logout();
            }
        } catch (err) {
            console.log(err);
        }
    }, [account, inProgress, instance, logout]);

    const { mutate: mutateNewFilterSetting } = useMutation((settingData) => dispatch(postNewFilterSetting(settingData)), {
        onSuccess: (data, settingData) => {
            if (data) {
                dispatch(updateSettingFilter(settingData));
            }
        },
    });

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        if (Array.isArray(defaultFilter?.customFilters)) {
            const calendarStartFrom = defaultFilter.customFilters.find((item) => item.key === 'calendarStartFrom')?.text;
            calendarStartFrom && setCalendarStartFrom(calendarStartFrom);
        }
    }, [defaultFilter]);

    const handleScroll = () => {
        const position = window.pageYOffset;
        setScrollPosition(position);
    };

    const changeCalendarStartFrom = (value) => {
        const newSettings = modifyCustomFilters(defaultFilter, 'calendarStartFrom', value);
        mutateNewFilterSetting(newSettings);
    };

    const onLogout = async () => {
        try {
            dispatch(logout());
            await instance.logoutRedirect({ postLogoutRedirectUri: '/login' });
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div className={classNames.header}>
            <div
                className={classNames.innerContainer}
                style={{ top: scrollPosition > 56 ? -100 : 56 - scrollPosition, right: realPageWidth > 1000 ? 144 : 20 }}
            >
                <FontAwesomeIcon id='userIcon' icon={faCircleUser} onClick={toggleTeachingShowUserPopup} className={classNames.icon} />
            </div>
            {showUserPopup && (
                <FixPositionBubble
                    target={'#userIcon'}
                    onDismiss={toggleTeachingShowUserPopup}
                    calloutProps={{
                        directionalHint: DirectionalHint.rightTopEdge,
                        calloutWidth: 400,
                        calloutheight: 50,
                    }}
                >
                    <div>
                        <div className={classNames.avatarOverlay}></div>
                        <Persona
                            className={classNames.avatar}
                            onClick={() => {
                                history.push('/notification-management');
                            }}
                            imageUrl={user.imageUrl ? `${SOURCE_API}${user.imageUrl}` : ''}
                            size={PersonaSize.size72}
                        />
                        <div className={classNames.userName}>
                            {user.firstName} {user.lastName}
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 20 }}>
                            <div className={classNames.title}>Calendar starting point</div>
                            <ToggleButtonPill
                                toggle={calendarStartFrom === 'week'}
                                onClick={() => changeCalendarStartFrom(calendarStartFrom === 'month' ? 'week' : 'month')}
                                leftText='Month'
                                rightText='Week'
                            />
                        </div>
                        <div className={classNames.signOut} onClick={onLogout}>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <FontAwesomeIcon id='logout' icon={faSignOut} />
                            </div>
                        </div>
                        {/* {logoutConfirmation && (
                            <DeleteConfirmation
                                label='Are you sure you want to logout?'
                                target={`#logout`}
                                toggleTeaching={toggleLogoutConfirmation}
                                onClickConfirm={onLogout}
                            />
                        )} */}
                    </div>
                </FixPositionBubble>
            )}
        </div>
    );
};

export default CalendarHeaderRight;
