export const buttonStyle = (selected, defaultBackground = '') => ({
    root: {
        borderRadius: 4,
        height: 32,
        width: 40,
        minWidth: 40,
        borderColor: "#006CAD",
        color: "#006CAD",
        backgroundColor: selected ? "#006CAD" : defaultBackground,
        marginRight: 15,
    },
    label: { fontWeight: 400 },
    rootHovered: {
        backgroundColor: "#006CAD"
    },
});

export const divButtonStyle = (selected, defaultBackground = '') => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 4,
    height: 28,
    width: 36,
    minWidth: 36,
    border: "1px solid #006CAD",
    color: "#006CAD",
    backgroundColor: selected ? "#006CAD" : defaultBackground,
    marginRight: 10,
    cursor: "pointer",
});