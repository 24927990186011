import React, { useCallback, useRef, useState } from "react";
import { mergeStyleSets } from "@fluentui/react/lib/Styling";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import { DefaultButton, PrimaryButton } from "@fluentui/react";
import { defaultButtonStyles, primaryButtonStyles } from "../../utils/theme";
import AsyncSelect from "react-select/async";
import { REACT_APP_GOOGLE_MAPS_API_KEY } from "../../utils/constants";

const classNames = mergeStyleSets({
  pickerControlContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginRight: 8,
    marginTop: 16,
  },
  cancel: {
    marginRight: 10,
  },
  search: {
    marginBottom: 20,
  },
});

const libraries = ["places"];

const center = {
  lat: 55.7689,
  lng: 12.5863,
};

const MapPickerComponent = ({
  geoCoordinate: { latitude, longitude },
  setGeoCoordinate,
  toggleMapPickerVisible,
}) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
  });

  const [marker, setMarker] = useState({ latitude, longitude });

  const onMapClick = useCallback((e) => {
    setMarker({
      latitude: e.latLng.lat(),
      longitude: e.latLng.lng(),
    });
  }, []);

  const mapRef = useRef();
  const onMapLoad = useCallback((map) => {
    mapRef.current = map;
  }, []);

  const panTo = useCallback(({ lat, lng }) => {
    setMarker({ latitude: lat, longitude: lng });
    mapRef.current.panTo({ lat, lng });
    mapRef.current.setZoom(14);
  }, []);

  if (loadError) return "Error...";
  if (!isLoaded) return "Loading...";

  const submitGeoCoordinates = () => {
    setGeoCoordinate(marker);
    toggleMapPickerVisible();
  };

  return (
    <div>
      <LocationSearchTextField panTo={panTo} />
      <GoogleMap
        id="map"
        mapContainerStyle={{ height: 430, width: 500 }}
        zoom={8}
        center={center}
        // options={options}
        onClick={onMapClick}
        onLoad={onMapLoad}
      >
        {marker.latitude && marker.longitude && (
          <Marker
            key={`${marker.latitude}-${marker.longitude}`}
            position={{ lat: marker.latitude, lng: marker.longitude }}
          />
        )}
      </GoogleMap>
      <div className={classNames.pickerControlContainer}>
        <DefaultButton
          styles={defaultButtonStyles}
          className={classNames.cancel}
          text="Annullere"
          onClick={() => toggleMapPickerVisible()}
        />
        <PrimaryButton
          styles={primaryButtonStyles}
          text="Gem"
          onClick={submitGeoCoordinates}
        />
      </div>
    </div>
  );
};

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    color: "#000000",
  }),
};

// https://github.com/leighhalliday/google-maps-react-2020/blob/master/src/App.js
const LocationSearchTextField = ({ panTo }) => {
  // const { ready, value, suggestions: { status, data }, setValue, clearSuggestions, } = usePlacesAutocomplete({
  const {
    ready,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      location: { lat: () => 55.7689, lng: () => 12.5863 },
      radius: 100 * 1000,
    },
  });

  // React.useEffect(() => {
  //     console.log('status change', status, data)
  // }, [status, data]);

  // https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service#AutocompletionRequest
  const handleSelect = async (address) => {
    setValue(address, false);
    clearSuggestions();

    try {
      const results = await getGeocode({ address });
      const { lat, lng } = await getLatLng(results[0]);
      panTo({ lat, lng });
    } catch (error) {
      console.log("Error: ", error);
    }
  };

  const filterLocations = () => {
    if (status === "OK") {
      return data.map(({ reference, description }) => ({
        value: reference,
        label: description,
      }));
    }
    return [];
  };

  const loadOptions = (inputValue, callback) => {
    callback(filterLocations());
  };

  return (
    <div className={classNames.search}>
      <AsyncSelect
        cacheOptions
        loadOptions={loadOptions}
        onInputChange={(value) => setValue(value)}
        onChange={(option) => handleSelect(option.label)}
        isDisabled={!ready}
        styles={customStyles}
      />
    </div>
  );
};

export default MapPickerComponent;
