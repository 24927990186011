// import { Pivot, PivotItem } from '@fluentui/react/lib/Pivot';
import { mergeStyleSets } from '@fluentui/react/lib/Styling';
import React from 'react';
import VacationView from './VacationView';

const classNames = mergeStyleSets({
    root: {
        display: 'flex',
        flexDirection: 'row',
        background: '#F1F1F1',
        width: '100%'
    },
});

const VacationPlanning = () => {
    return (
        <div className={classNames.root}>
            <div className={classNames.pageContentContainer}>
                <div className={classNames.pageInnerContainer}>
                    <VacationView />
                </div>
            </div>
        </div>   
    )
}

export default VacationPlanning;
