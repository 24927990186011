import React, { Fragment, useEffect, useState } from "react";
import { TeachingBubble } from "@fluentui/react/lib/TeachingBubble";
import { defaultButtonStyles, primaryButtonStyles } from "../../utils/theme";
import { DefaultButton, PrimaryButton } from "@fluentui/react";
import { mergeStyleSets } from "@fluentui/react/lib/Styling";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionSquare } from "@fortawesome/pro-regular-svg-icons";
import { ChoiceGroup } from "@fluentui/react/lib/ChoiceGroup";
import { DirectionalHint } from "@fluentui/react/lib/Callout";
import { useHistory } from "react-router-dom";

const classNames = mergeStyleSets({
  buttonControls: {
    marginTop: "24px",
    display: "flex",
    justifyContent: "flex-end",
    marginRight: 8,
  },
  cancel: {
    marginRight: 10,
  },
  title: {
    textAlign: "left",
    margin: "0px 0px 7px",
  },
  label: {
    textAlign: "left",
    margin: 0,
    lineHeight: "24px",
  },
});

const teachingBubbleConfirmationStyles = {
  root: {
    display: "none",
  },
  headline: {
    fontSize: 30,
    display: "flex",
    justifyContent: "center",
    color: "#fff",
  },
  subText: {
    color: "black",
    fontSize: 14,
  },
  bodyContent: {
    padding: "0px 0px",
    display: "flex",
  },
  header: {
    width: "120px",
    display: "flex",
    alignItems: "initial",
    justifyContent: "center",
    marginBottom: "0px !important",
    backgroundColor: "#006CAD",
    padding: "20px 0px",
  },
  body: {
    padding: "20px 24px",
  },
};

const choiceGroupStyles = {
  root: {
    marginTop: "12px",
  },
  beakCurtain: {
    borderRadius: "8px",
  },
};

const TeamConfirmationDialog = ({
  key,
  target,
  toggleTeaching,
  machineTeamList,
  machine,
  plan,
  machineRequirementIndex,
}) => {
  const history = useHistory();
  const [options, setOptions] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState("");

  useEffect(() => {
    const list =
      machineTeamList &&
      machineTeamList.filter((t) => t.machineName === machine["machineName"]);
    const res = [];

    if (list.length < 0) return [];

    for (let i = 0; i < list.length; i++) {
      const teams = list[i].teams;
      for (let j = 0; j < teams.length; j++) {
        res.push({ key: teams[j].id, text: teams[j].name });
      }
    }
    setOptions(res);
  }, []);

  const onClickToEditPage = () => {
    history.push(
      `/planning/${plan["projectId"]}/${machineRequirementIndex}/${selectedTeam}`
    );
  };

  const text =
    options.length > 1 ? (
      <Fragment>
        has <strong>teams</strong>,
      </Fragment>
    ) : (
      <Fragment>
        has a <strong>team</strong>,
      </Fragment>
    );

  return (
    options.length > 0 && (
      <TeachingBubble
        calloutProps={{ directionalHint: DirectionalHint.bottomCenter }}
        styles={teachingBubbleConfirmationStyles}
        targetElement={target}
        hasSmallHeadline={true}
        // onDismiss={toggleTeaching}
        headline={
          <FontAwesomeIcon
            icon={faQuestionSquare}
            className={classNames.warningIcon}
          />
        }
        closeButtonAriaLabel="Close"
      >
        <>
          <h3 className={classNames.title}>Question!</h3>
          <p className={classNames.label}>
            This machine {text} do you want to add it to the plan?
          </p>
          <div>
            <ChoiceGroup
              styles={choiceGroupStyles}
              className={"radioGroup"}
              options={options}
              onChange={(e, item) => setSelectedTeam(item.key)}
            />
          </div>
          <div className={classNames.buttonControls}>
            <DefaultButton
              styles={defaultButtonStyles}
              className={classNames.cancel}
              text="No thanks"
              onClick={toggleTeaching}
            />
            <PrimaryButton
              styles={primaryButtonStyles}
              text="Sure!"
              onClick={onClickToEditPage}
              disabled={selectedTeam !== "" ? false : true}
            />
          </div>
        </>
      </TeachingBubble>
    )
  );
};

export default TeamConfirmationDialog;
