// import { Pivot, PivotItem } from '@fluentui/react/lib/Pivot';
import { mergeStyleSets } from "@fluentui/react/lib/Styling";
import { datesGenerator } from "dates-generator";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { getHolidayList } from "../../../redux/app/app.actions";
import {
  clearPlanOverlayProjectId,
  getPlans,
} from "../../../redux/plan/plan.actions";
import {
  getMachines,
  selectNewDate,
  setFilter,
} from "../../../redux/machines/machines.actions";
import { getPrebookings } from "../../../redux/prebooking/prebooking.actions";
import {
  CALENDAR_STEP_WIDTH,
  CALENDAR_WEEKVIEW_STEP_WIDTH,
  months,
  techDepartmentOptions,
} from "../../../utils/constants";
import {
  calculateFinalDates,
  createVerticalPositionMap,
  findYearOfDates,
  getCalendarHoliday,
  getLastDate,
  getMonday,
  getMonthIndicator,
  lighterDarkerColor,
  removeFirstWeek,
  startCalendarFromMonth,
  startCalendarFromSelectedWeek,
} from "../../../utils/utils";
import {
  CalendarHeaderLeft,
  DayLabel,
  MonthLabel,
  WeekLabel,
} from "../../common/calendar";
import { getStaff } from "../../../redux/user/user.actions";
import MachineBar from "./MachineBar";
import PlanOverlay from "../planning/planOverlay/PlanOverlay";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/pro-regular-svg-icons";
import { useHistory } from "react-router-dom";
import { Dropdown } from "@fluentui/react";
import { outlinedDropDownStyles } from "../../../utils/theme";
const heightOfTop = 120;

const MachineView = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { user } = useSelector((state) => state.user);
  const { workingRole } = user;
  const { techDepartment } = useSelector((state) => state.machine.filter);
  const { displayWeekView } = useSelector((state) => state.machine);
  const calendarStartFrom =
    useSelector((state) => state.user.user.defaultFilter.customFilters)?.find(
      (item) => item.key === "calendarStartFrom"
    )?.text || "month";

  const holidayRef = useRef(null);

  const [calendar, setCalendar] = useState({});
  const [dates, setDates] = useState([]);
  const [calendarStepWidth, setCalendarStepWidth] = useState(
    displayWeekView ? CALENDAR_WEEKVIEW_STEP_WIDTH : CALENDAR_STEP_WIDTH
  );
  const [machines, setMachines] = useState([]);
  const [plansData, setPlansData] = useState([]);

  const classNames = mergeStyleSets({
    root: {
      background: "#F1F1F1",
      width: "100%",
    },
    topContainer: {
      display: "flex",
      justifyContent: "space-between",
      position: "sticky",
      top: 0,
      left: 0,
      height: heightOfTop,
      background: "#f1f1f1",
      width: machines.length
        ? machines.length * 200 + 100
        : "calc(100vw - 125px)",
      zIndex: 1004,
    },
    calendarContainer: {
      display: "flex",
      // height: document.documentElement.clientHeight - heightOfTop - 20,
      overflowY: "clip",
    },
    dateContainer: {
      display: "flex",
      position: "sticky",
      top: 0,
      left: 0,
      background: "#f1f1f1",
      zIndex: 1003,
    },
    monthText: {
      fontSize: 26,
      fontWeight: "bold",
      textAlign: "center",
    },
    days: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
    },
    dayOfWeeks: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      marginBottom: 8,
    },
    weeks: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      marginBottom: 4,
    },
    week: {
      minWidth: 175,
      height: 20,
      background: "#E7E7E7",
      fontSize: 11,
      lineHeight: 20,
      borderRadius: 10,
    },
    machineList: {
      margin: "70px 0 0 100px",
      display: "flex",
    },
    machineNameContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: 180,
      height: 40,
      background: "#dfdfdf",
      borderRadius: 4,
      marginRight: 20,
    },
    machineName: {
      fontWeight: 700,
      fontSize: 14,
      width: "90%",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      textAlign: "center",
    },
    closeButtonOutside: {
      position: "sticky",
      top: 0,
      left: 12,
      cursor: "pointer",
      background: "#DFDFDF",
      width: "100%",
      zIndex: 100,
      marginBottom: 5,
    },
  });

  const { data: machinesData } = useQuery("machines", dispatch(getMachines));
  const { data: prebookingsData } = useQuery(
    "prebookings",
    dispatch(getPrebookings)
  );
  const { data: allPlansData, refetch: refetchPlans } = useQuery("plans", () =>
    dispatch(getPlans(new Date().getFullYear()))
  );

  const filterPlansData = () => {
    const startDate = dates[0][0];
    const endDate = getLastDate(dates);
    const startCalendar = new Date(
      startDate.year,
      startDate.month,
      startDate.date
    );
    const endCalendar = new Date(endDate.year, endDate.month, endDate.date);
    const filteredPlansData = allPlansData.filter(
      (plan) =>
        plan.projectNo &&
        plan.projectName &&
        plan.section &&
        !plan["inactive"] &&
        (plan["machineRequirements"].some((requirement) =>
          requirement.machines.some(
            (machine) =>
              machine.techDepartments?.includes(techDepartment) &&
              new Date(machine.start) <= endCalendar &&
              new Date(machine.end) >= startCalendar
          )
        ) ||
          plan["machineRequirements"].some(
            (requirement) => !requirement.machines.length
          ))
    );
    return filteredPlansData;
  };

  useEffect(() => {
    if (allPlansData && dates.length) {
      const filteredPlansData = filterPlansData();
      setPlansData(filteredPlansData);
    }
  }, [allPlansData, dates, techDepartment]);

  const verticalPositionMap = useMemo(
    () => createVerticalPositionMap(dates),
    [dates]
  );
  const horizontalPositionMap = useMemo(
    () =>
      machines.reduce(
        (acc, curr, index) => ({ ...acc, [curr.machineId]: index * 200 }),
        {}
      ),
    [machines]
  );

  const onClickChooseDate = (date) => {
    dispatch(selectNewDate(date));
    const array = new Array(displayWeekView ? 12 : 6);
    array[0] = datesGenerator({
      month: date.getMonth(),
      year: date.getFullYear(),
      startingDay: 1,
    });
    for (let i = 1; i < array.length; i++) {
      array[i] = datesGenerator({
        month: array[i - 1].nextMonth,
        year: array[i - 1].nextYear,
        startingDay: 1,
      });
    }
    let { finalDates, start, end } = calculateFinalDates(
      array,
      false,
      [0, 4].some((role) => workingRole.includes(role)) ? 12 : 4,
      date
    );
    if (calendarStartFrom === "month" && !displayWeekView) {
      finalDates[0] = startCalendarFromMonth(date, finalDates[0]);
    }
    // Find the start of calendar if choose to start from selected week
    if (calendarStartFrom === "week") {
      finalDates = startCalendarFromSelectedWeek(date, finalDates);
    }
    start = new Date(
      finalDates[0][0].year,
      finalDates[0][0].month,
      finalDates[0][0].date
    );
    // Find the holiday of calendar
    async function getDatesWithHolidays() {
      const yearOfDates = findYearOfDates(finalDates);
      let holidays = [];
      if (
        !holidayRef.current ||
        JSON.stringify(yearOfDates) !==
          JSON.stringify(holidayRef.current?.years)
      ) {
        for (const year of yearOfDates) {
          const currentHolidays = await dispatch(getHolidayList(year));
          holidays = [...holidays, ...(currentHolidays?.holidays || [])];
        }
        holidayRef.current = {
          years: yearOfDates,
          holidays: holidays,
        };
      } else {
        holidays = holidayRef.current.holidays;
      }
      finalDates = getCalendarHoliday(finalDates, holidays);
      setDates(finalDates);
    }
    getDatesWithHolidays();
    setDates(finalDates);
    const endDate = getLastDate(finalDates);
    end = new Date(endDate.year, endDate.month, endDate.date);

    setCalendar({
      ...calendar,
      nextMonth: array[array.length - 1].nextMonth,
      nextYear: array[array.length - 1].nextYear,
      previousMonth: array[0].previousMonth,
      previousYear: array[0].previousYear,
      start,
      end,
    });
  };

  useEffect(() => {
    refetchPlans();
    setCalendarStepWidth(
      displayWeekView ? CALENDAR_WEEKVIEW_STEP_WIDTH : CALENDAR_STEP_WIDTH
    );
    onClickChooseDate(getMonday(new Date()));
  }, [displayWeekView]);

  useEffect(() => {
    if (machinesData) {
      const machinesOfUserTechDepartment = machinesData
        .filter(
          (machine) =>
            !machine.inactive &&
            machine.techDepartments?.includes(techDepartment)
        )
        .sort((a, b) => a.sortIndex - b.sortIndex);
      setMachines(machinesOfUserTechDepartment);
    }
  }, [machinesData, techDepartment]);

  return (
    <div className={classNames.root}>
      <PlanOverlay />
      <div className={classNames.topContainer}>
        <FontAwesomeIcon
          icon={faArrowLeft}
          onClick={() => history.push("/")}
          style={{
            color: "#2A2A2A",
            fontSize: 25,
            position: "fixed",
            top: 22,
            left: 28,
          }}
        />
        <div style={{ position: "fixed", left: 100, top: 15 }}>
          <Dropdown
            selectedKey={techDepartment}
            name="projectType"
            onChange={(event, item) =>
              dispatch(
                setFilter({
                  techDepartment: item.key,
                })
              )
            }
            placeholder="Department"
            options={techDepartmentOptions}
            styles={outlinedDropDownStyles}
            calloutProps={{ doNotLayer: true }}
          />
        </div>
        <div className={classNames.machineList}>
          {machines.map((machine) => (
            <div
              key={machine.machineId}
              className={classNames.machineNameContainer}
            >
              <div className={classNames.machineName}>
                {machine.machineName}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div
        className={classNames.calendarContainer}
        style={{
          width: machines.length ? machines.length * 200 + 100 : "100vw",
        }}
      >
        <div className={classNames.dateContainer}>
          <div className={classNames.weeks} style={{ width: 20, marginTop: 5 }}>
            {dates.length &&
              getMonthIndicator(dates, months).map((monthData, indexMonth) => (
                <MonthLabel
                  isWeekView={displayWeekView}
                  index={indexMonth}
                  key={indexMonth + monthData["length"] + monthData["label"]}
                  length={monthData["length"]}
                  label={monthData["label"]}
                />
              ))}
          </div>

          <div className={classNames.weeks} style={{ marginLeft: 10 }}>
            {dates.length > 0 &&
              removeFirstWeek(
                dates,
                calendarStartFrom === "month" &&
                  !displayWeekView &&
                  Array.isArray(dates) &&
                  dates[0]?.length < 7
              ).map((week, indexWeek) => (
                <WeekLabel
                  isWeekView={displayWeekView}
                  index={indexWeek}
                  month={week[0]["month"]}
                  day={week[0]["date"]}
                  year={week[0]["year"]}
                  key={`${week[0]["month"]}-${week[0]["date"]}-${week[0]["year"]}`}
                />
              ))}
          </div>
          {!displayWeekView && (
            <>
              <div className={classNames.days}>
                {dates.length > 0 &&
                  dates.map((week) =>
                    week.map((each) => (
                      <DayLabel
                        label={each.date}
                        date={each}
                        key={JSON.stringify(each)}
                      />
                    ))
                  )}
              </div>
              <div className={classNames.dayOfWeeks}>
                {dates.length > 0 &&
                  dates.map((week) =>
                    week.map((each) => (
                      <DayLabel
                        label={each.date}
                        date={each}
                        key={JSON.stringify(each)}
                        isDaysOfWeek
                      />
                    ))
                  )}
              </div>
            </>
          )}
        </div>

        <div
          style={{
            minHeight: "calc(100vh - 305px)",
            width: machines.length
              ? machines.length * 200
              : "calc(100vw - 125px)",
            backgroundImage: 'url("./img/calendarBackground3-vertical.png")',
            backgroundRepeat: "repeat",
            backgroundPositionY:
              calendarStartFrom === "month" &&
              !displayWeekView &&
              Array.isArray(dates) &&
              dates[0]?.length < 7
                ? dates[0].length * CALENDAR_STEP_WIDTH
                : 0,
            height: calendarStepWidth * 7 * dates.length - 24,
            // height: document.documentElement.clientHeight - heightOfTop - 20,
          }}
        >
          <div
            id="machinePlanContainer"
            className="machinePlanContainer"
            style={{
              position: "absolute",
              top: heightOfTop,
              left: 100,
              zIndex: 556,
              height: calendarStepWidth * 7 * dates.length - 24,
              // height: document.documentElement.clientHeight - heightOfTop - 20,
            }}
          >
            {/* Machine service */}
            {horizontalPositionMap &&
              verticalPositionMap &&
              machines.map((machine) =>
                machine.machineService?.map((item, index) => {
                  let text = item["comment"];

                  if (item["reason"]) {
                    text += ` (${item["reason"]})`;
                  }

                  return (
                    <MachineBar
                      key={`machineService-${machine.machineId}-${index}`}
                      id={`machineService-${machine.machineId}-${index}`}
                      name={text}
                      color="255, 255, 255"
                      lighterColor="241, 241, 241"
                      textColor="#575756"
                      machine={machine}
                      start={item.start}
                      end={item.end}
                      hovedsagsNummer={null}
                      verticalPositionMap={verticalPositionMap}
                      horizontalPositionMap={horizontalPositionMap}
                      calendarStart={calendar.start}
                      calendarEnd={calendar.end}
                      indexMachineRequirement={null}
                      type="machineService"
                      borderWidth="2px"
                      borderStyle="solid"
                      borderColor={`#868685`}
                    />
                  );
                })
              )}
            {/* Prebooking */}
            {horizontalPositionMap &&
              verticalPositionMap &&
              prebookingsData
                ?.filter(
                  (booking) =>
                    booking.techDepartments.includes(techDepartment) &&
                    machines.some(
                      (machine) => machine.machineId === booking.machineId
                    )
                )
                .map((booking) => (
                  <MachineBar
                    key={`prebooking-${booking.id}`}
                    id={`prebooking-${booking.id}`}
                    name={booking.projectName}
                    color="227,181,5"
                    lighterColor={lighterDarkerColor("227,181,5", 10)}
                    textColor="#fff"
                    machine={machines.find(
                      (machine) => machine.machineId === booking.machineId
                    )}
                    start={booking.start}
                    end={booking.end}
                    hovedsagsNummer={null}
                    verticalPositionMap={verticalPositionMap}
                    horizontalPositionMap={horizontalPositionMap}
                    calendarStart={calendar.start}
                    calendarEnd={calendar.end}
                    indexMachineRequirement={null}
                    type="prebooking"
                    borderWidth={null}
                    borderStyle={null}
                    borderColor={null}
                    isHidden={true}
                    isGhost
                  />
                ))}

            {/* Plan */}
            {horizontalPositionMap &&
              verticalPositionMap &&
              plansData.map((plan) =>
                plan["machineRequirements"].map(
                  (machineRequirement, machineRequirementIndex) =>
                    machineRequirement["machines"]
                      ?.filter((machine) =>
                        machines.some(
                          (item) => item.machineId === machine.machineId
                        )
                      )
                      .map((machine, machineIndex) => {
                        // const isDone = ![
                        //     machineRequirement.drivers ?? [],
                        //     machineRequirement.workers ?? [],
                        //     machineRequirement.managers ?? [],
                        // ].some((array) => array.length === 0) || machineRequirement.done;
                        return (
                          <MachineBar
                            key={`plan-${plan.projectId}-${machine.machineId}-${machineIndex}`}
                            id={plan.projectId}
                            name={plan.projectName}
                            color={plan.color}
                            lighterColor={
                              machineRequirement?.isHidden
                                ? lighterDarkerColor(plan.color, 10)
                                : plan.color
                            }
                            textColor="#fff"
                            machine={machines.find(
                              (item) => item.machineId === machine.machineId
                            )}
                            start={machine.start}
                            end={machine.end}
                            hovedsagsNummer={plan.hovedsagsNummer}
                            verticalPositionMap={verticalPositionMap}
                            horizontalPositionMap={horizontalPositionMap}
                            calendarStart={calendar.start}
                            calendarEnd={calendar.end}
                            indexMachineRequirement={machineRequirementIndex}
                            type="plan"
                            borderWidth={
                              plan.reason || plan.isQuotation ? "4px" : null
                            }
                            borderStyle={
                              plan.reason || plan.isQuotation ? "solid" : null
                            }
                            borderColor={
                              plan.reason || plan.isQuotation
                                ? `${
                                    plan.reason
                                      ? `rgb(203,3,63) rgb(203,3,63)`
                                      : "transparent transparent"
                                  } ${plan.isQuotation ? `rgb(227,181,5)` : ""}`
                                : null
                            }
                            isGhost={!machineRequirement.done}
                            isHidden={machineRequirement?.isHidden}
                          />
                        );
                      })
                )
              )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MachineView;
