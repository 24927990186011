import React, { useState, useEffect } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { mergeStyleSets } from "@fluentui/react/lib/Styling";
import { Persona, PersonaSize } from "@fluentui/react/lib/Persona";
import { useHistory, useLocation } from "react-router-dom";
import { Label } from "@fluentui/react";
import { logout } from "../../redux/user/user.actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faSignOut } from "@fortawesome/pro-regular-svg-icons";
import { useBoolean } from "@fluentui/react-hooks";
import { TooltipForText, DeleteConfirmation } from "./";
import { SOURCE_API } from "../../utils/constants";
import { useMsal, useAccount } from "@azure/msal-react";
import { changeSearchOverlay } from "../../redux/app/app.actions";

const Header = ({ title = "", subTitle = "", user, logout }) => {
  const dispatch = useDispatch();
  const realPageWidth = document.documentElement.offsetWidth;
  const classNames = mergeStyleSets({
    header: [
      {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        margin: "0 0 40px 0",
      },
    ],
    innerContainer: {
      position: "fixed",
      width: 108,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    avatar: {
      width: 40,
      cursor: "pointer",
      borderRadius: "50%",
      selectors: {
        ":hover": {
          borderRadius: "50%",
          boxShadow: "0px 0px 30px #006CAD",
          transition: "all 0.2s ease-in",
        },
      },
    },
    titleContainer: {
      display: "flex",
      position: "relative",
    },
    title: [
      {
        fontWeight: "bold",
        fontSize: "32px",
        lineHeight: "40px",
      },
    ],
    subTitle: [
      {
        fontSize: "11px",
        lineHeight: "11px",
      },
    ],
    signOut: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      position: "relative",
      top: 10,
      left: 30,
      cursor: "pointer",
      color: "#006CAD",
      fontSize: 24,
      height: 34,
      width: 34,
      selectors: {
        ":hover": {
          backgroundColor: "rgb(232, 232, 232)",
        },
      },
    },
    icon: {
      marginRight: 2,
      fontSize: 24,
      color: "#006CAD",
      cursor: "pointer",
    },
  });
  const [logoutConfirmation, { toggle: toggleLogoutConfirmation }] =
    useBoolean(false);
  const location = useLocation();
  const showLogout = location.pathname.includes("/notification-management");
  const history = useHistory();
  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };
  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0] || {});

  useEffect(() => {
    try {
      if (!account && inProgress === "none") {
        logout();
      }
    } catch (err) {
      console.log(err);
    }
  }, [account, inProgress, instance, logout]);

  const onLogout = async () => {
    try {
      logout();
      await instance.logoutRedirect({ postLogoutRedirectUri: "/login" });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <>
      <div className={classNames.header}>
        <div className={classNames.titleContainer}>
          <div>
            <Label className={classNames.title}>{title}</Label>
            <Label className={classNames.subTitle}>{subTitle}</Label>
          </div>
          {showLogout && (
            <div
              className={classNames.signOut}
              id="logout"
              onClick={toggleLogoutConfirmation}
            >
              <TooltipForText text={"Logout"}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <FontAwesomeIcon icon={faSignOut} />
                </div>
              </TooltipForText>
            </div>
          )}
        </div>
        <div
          className={classNames.innerContainer}
          style={{
            top: scrollPosition > 56 ? -100 : 56 - scrollPosition,
            right: realPageWidth > 1000 ? 144 : 20,
          }}
        >
          <Persona
            className={classNames.avatar}
            onClick={() => {
              history.push("/notification-management");
            }}
            imageUrl={user.imageUrl ? `${SOURCE_API}${user.imageUrl}` : ""}
            // text={user ? `${user.firstName} ${user.lastName}` : ''}
            size={PersonaSize.size40}
          />
        </div>
      </div>
      {logoutConfirmation && (
        <DeleteConfirmation
          label="Are you sure you want to logout?"
          target={`#logout`}
          toggleTeaching={toggleLogoutConfirmation}
          onClickConfirm={onLogout}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.user,
});

export default connect(mapStateToProps, { logout })(Header);
