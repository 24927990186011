import { DirectionalHint } from '@fluentui/react';
import { TooltipHost } from '@fluentui/react/lib/Tooltip';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

const calloutProps = {
	gapSpace: 0,
	styles: {
		beakCurtain: {
			backgroundColor: '#fff'
		},
		calloutMain: {
			height: 30
		}
	},
}

const TooltipForText = ({children, text, noWidth = false, direction = DirectionalHint.topCenter, positionAbsolute = false, zIndex}) => {
    const defaultFilter = useSelector((state) => state.user.user.defaultFilter);
    const userZoomRatio = parseFloat(defaultFilter?.customFilters?.find(item => item.key === 'zoomRatio')?.text || 1);
	const [bubbleInitiated, setBubbleInitiated] = useState(false);
	const hostStyles = {
		root: {
			display: 'inline-block',
			padding: 0,
			width: noWidth ? '' : '100%',
			zIndex: zIndex || 'unset',
		},
	};

	const getTooltipPosition = (e) => {
		setBubbleInitiated(false);
		setTimeout(() => {
			const mousePosition = {x: e.pageX, y: e.pageY};
			const tooltip = document.querySelector('.ms-Tooltip');
			const beak = document.querySelector('.ms-Tooltip .ms-Callout-beak');
			const realMouseX = (mousePosition.x - window.pageXOffset)/userZoomRatio;
			const realMouseY = (mousePosition.y - window.pageYOffset)/userZoomRatio;
			const pageWidth = document.documentElement.offsetWidth;
			const pageHeight = document.documentElement.offsetHeight;
			const rightPosition = document.documentElement.offsetWidth/userZoomRatio - realMouseX;
			const bottomPosition = (mousePosition.y - window.pageYOffset)/userZoomRatio;

			if (tooltip) {
				// Only set position when the tooltip go outside the bounds
				if (realMouseX > pageWidth || positionAbsolute) {
					tooltip.style.right = `${rightPosition - tooltip.offsetWidth/2}px`;
					beak.style.right = `${tooltip.offsetWidth/2 - 11.3}px`; // 11.3 is the length of beak
				}
				if (positionAbsolute && direction === DirectionalHint.bottomCenter) {
					tooltip.style.top = `${parseInt(tooltip.style.top.slice(0,-2)) + 20}px`;
				}
				if (realMouseY > pageHeight) {
					tooltip.style.bottom = `-${bottomPosition - tooltip.offsetHeight/2}px`;
				}
				setBubbleInitiated(true);
			}
		}, 500);
	}
	
	return (
		<TooltipHost
			content={text}
			calloutProps={calloutProps}
			styles={hostStyles}
			directionalHint={direction}

			tooltipProps={{
				calloutProps: {
					styles: {
						root: { visibility: bubbleInitiated ? 'unset' : 'hidden'},
						beakCurtain: { backgroundColor: '#fff'},
					},
				},
				styles: {
					content: {
						height: 18,
					}
				}
			}}
		>
			{React.Children.map(children, c =>
				React.cloneElement(c, {onMouseOver: getTooltipPosition})
			)}
		</TooltipHost>
	)
};

export default TooltipForText;