import React from 'react';
import { mergeStyleSets } from '@fluentui/react/lib/Styling';
import 'moment/locale/da';
import { CALENDAR_STEP_WIDTH, CALENDAR_WEEKVIEW_STEP_WIDTH } from '../../../utils/constants';
import { getWeekNumber } from '../../../utils/utils';

const classNames = mergeStyleSets({
    week: {
        height: 20,
        fontSize: 11,
        lineHeight: 20,
        position: 'relative'
    },
    weekText: {
        marginTop: 2,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 20,
    },
});

const WeekLabel = ({month, day, year, index, isWeekView = false}) => {

    return (
        <div className={classNames.week} style={{minHeight: isWeekView ? CALENDAR_WEEKVIEW_STEP_WIDTH * 7 : CALENDAR_STEP_WIDTH * 7}}>
            <div className={classNames.weekText}>
                {getWeekNumber(month+1 , day, year)}
            </div>
            <div style={{position: 'absolute', top: 0, borderTop: '2px solid #868685', height: '100%', width: '20px' }}/>
        </div>
    )
}

export default WeekLabel;
