import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import storage from 'redux-persist/lib/storage';
import userReducer from './user/user.reducer';
import messageReducer from './message/message.reducer';
import machineReducer from './machines/machines.reducer';
import planReducer from './plan/plan.reducer';
import defaultDataReducer from './defaultData/defaultData.reducer';
import appReducer from './app/app.reducer';
import prebookingReducer from './prebooking/prebooking.reducer';

const persistConfig = {
    key: 'root',
    storage: storage,
    whitelist: ['app', 'user', 'defaultData', 'machine'],
    stateReconciler: autoMergeLevel2
}

const rootReducer = combineReducers({
    app: appReducer,
    user: userReducer,
    message: messageReducer,
    machine: machineReducer,
    plan: planReducer,
    prebooking: prebookingReducer,
    defaultData: defaultDataReducer,
});

export default persistReducer(persistConfig, rootReducer);
