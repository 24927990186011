import React from "react";
import { mergeStyleSets } from "@fluentui/react/lib/Styling";
import { Panel } from "@fluentui/react";
import { DefaultButton, PrimaryButton } from "@fluentui/react";
import { Stack } from "@fluentui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/pro-regular-svg-icons";

const classNames = mergeStyleSets({
  titleContainer: {
    width: "100%",
    wordWrap: "break-word",
    fontSize: 32,
    fontWeight: 700,
    marginTop: 132,
  },
  contentContainer2: {
    wordWrap: "break-word",
    fontSize: 14,
    fontWeight: 400,
    marginTop: 132,
  },
  actionButtonContainer: {
    position: "absolute",
    bottom: 32,
    right: 32,
  },
});

export const defaultButtonStyle = (overlayMode) => {
  return {
    root: {
      backgroundColor: overlayMode?.noBGColor ?? "#fff",
      borderRadius: 4,
      height: 40,
      borderColor: overlayMode?.noBGColor ?? "#fff",
      color: overlayMode?.noColor ?? "#000",
    },
    label: { fontWeight: 400 },
    rootHovered: {
      background: "#a1cce7",
      borderColor: "#a1cce7",
      color: "#fff",
    },
  };
};

const primaryButtonStyle = (overlayMode) => {
  return {
    root: {
      borderRadius: 4,
      height: 40,
      backgroundColor: overlayMode?.confirmBGColor ?? "#006CAD",
      color: overlayMode?.confirmColor ?? "#fff",
    },
    label: { fontWeight: 400 },
    rootHovered: {},
  };
};

const overlayStyle = (overlayMode) => {
  return {
    main: {
      overflow: "visible",
      minWidth: "25%",
      backgroundColor: overlayMode?.backgroundColor ?? "#DFDFDF",
      color: overlayMode?.color ?? "#000",
    },
  };
};

const OverlayConfirm = ({
  overlayMode,
  onClose,
  title,
  content,
  content2,
  onClickConfirm,
  firstButtonText = "No",
  secondButtonText = "Confirm",
}) => {
  const noButtonStyle = defaultButtonStyle(overlayMode);
  const confirmButtonStyle = primaryButtonStyle(overlayMode);
  return (
    <Panel
      // To entirely disable the default dismiss behavior:
      // 1. Don't provide an onDismiss prop
      isOpen={!!overlayMode} // 2. Control the visibility
      hasCloseButton={false}
      styles={() => overlayStyle(overlayMode)}
    >
      <div className={classNames.titleContainer}>
        <div>
          <FontAwesomeIcon icon={faExclamationTriangle} />
        </div>
        {title}
      </div>
      <div>{content}</div>
      <div className={classNames.contentContainer2}>{content2}</div>
      <div className={classNames.actionButtonContainer}>
        <Stack horizontal tokens={{ childrenGap: 20 }}>
          <DefaultButton
            styles={noButtonStyle}
            className={classNames.cancel}
            text={firstButtonText}
            onClick={onClose}
          />
          <PrimaryButton
            styles={confirmButtonStyle}
            text={secondButtonText}
            onClick={onClickConfirm}
          />
        </Stack>
      </div>
    </Panel>
  );
};

export default OverlayConfirm;
