import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { TeachingBubble } from '@fluentui/react/lib/TeachingBubble';

const FixPositionBubble = ({children, onDismiss, target, mainPositionRight, mainPositionTop, beakPositionRight, calloutProps}) => {
    const defaultFilter = useSelector((state) => state.user.user.defaultFilter);
    const [bubbleInitiated, setBubbleInitiated] = useState(false);

    useEffect(() => {
        setBubbleInitiated(false);
        setTimeout(() => {
            const teachingBubble = document.querySelectorAll('.ms-TeachingBubble')[0];
            const beak = document.querySelectorAll('.ms-Callout-beak')[0];
            if (teachingBubble) {
                if (mainPositionRight) {
                    teachingBubble.style.right = mainPositionRight;
                }
                if (mainPositionTop) {
                    teachingBubble.style.top = mainPositionTop;
                }
                beak.style.right = beakPositionRight;
                setBubbleInitiated(true);
            }
        }, 100)
    },[defaultFilter]);

    return (
        <TeachingBubble
            target={target}
            styles={{
                subText: {color: 'black'},
                subComponentStyles: {
                    callout: {
                        calloutMain: {
                            display: bubbleInitiated ? 'block' : 'none',
                        },
                        beak: {
                            display: bubbleInitiated ? 'block' : 'none',
                        },
                    }
                }
            }}
            onDismiss={onDismiss}
            calloutProps={calloutProps}
        >
            {children}
        </TeachingBubble>
    )
}

export default FixPositionBubble;