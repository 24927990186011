import axios from "axios";
import setDefaults from "../../utils/setDefaults";
import { checkTokenValidity } from "../user/user.actions";
import { saveMessage } from "../message/message.actions";
import { MessageBarType } from "@fluentui/react";
import { SOURCE_API } from "../../utils/constants";

export const postNewFilterSetting =
  (settingData, notification = true) =>
  async (dispatch) => {
    try {
      setDefaults(localStorage.getItem("token"));
      const response = await axios.put(
        `${SOURCE_API}/api/User/SaveFilterSettingAsync`,
        settingData
      );

      if (response.data.succeeded === true) {
        notification &&
          dispatch(
            saveMessage("Update setting successfully!", MessageBarType.success)
          );
      } else {
        dispatch(saveMessage("There was an error"));
      }
      return response.data.succeeded;
    } catch (error) {
      dispatch(checkTokenValidity(error));
    }
  };
