/* eslint-disable no-unused-expressions */
import { mergeStyleSets } from "@fluentui/react/lib/Styling";
import { datesGenerator } from "dates-generator";
import "moment/locale/da";
import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useBodyClass } from "../../../hooks";
import { getHolidayList } from "../../../redux/app/app.actions";
import { selectNewDate } from "../../../redux/machines/machines.actions";
import { getPlans } from "../../../redux/plan/plan.actions";
import { getStaffs } from "../../../redux/user/user.actions";
import {
  allTechDepartmentOptions,
  CALENDAR_PAGE,
  CALENDAR_STEP_WIDTH,
  CALENDAR_WEEKVIEW_STEP_WIDTH,
  hasEditRight,
  months,
  techDepartmentOptions,
} from "../../../utils/constants";
import {
  calculateFinalDates,
  createVerticalPositionMap,
  findYearOfDates,
  getCalendarHoliday,
  getMonthIndicator,
  removeFirstWeek,
  startCalendarFromMonth,
  startCalendarFromSelectedWeek,
} from "../../../utils/utils";
import {
  CalendarControlsRight,
  CalendarHeaderLeft,
  DayLabel,
  MonthLabel,
  WeekLabel,
} from "../../common/calendar";
import PlanOverlay from "./planOverlay/PlanOverlay";
import SelectUserOverlay from "./planOverlay/SelectUserOverlay";
import StaffPlan from "./StaffPlan";
import StaffVacationItem from "./StaffVacationItem";

// https://dev.to/aibrahim3546/creating-a-custom-calendar-in-react-from-scratch-1hej
// https://gist.github.com/aibrahim3546/dc68f9525f0f206ff08b1d09ee3adab5
const VacationView = () => {
  const { displayWeekView, selectedDayOnCalendar } = useSelector(
    (state) => state.machine
  );
  const classNames = mergeStyleSets({
    topContainer: {
      display: "flex",
      justifyContent: "space-between",
      position: "sticky",
      top: 0,
      left: 0,
      height: 100,
      background: "#f1f1f1",
      zIndex: 1003,
      paddingLeft: 20,
    },
    topAndCalendarSeparator: {
      height: 100,
      background: "#F1F1F1",
      position: "sticky",
      top: 0,
      zIndex: 1003,
      width: "10000%",
    },
    calendarContainer: {
      display: "flex",
    },
    monthText: {
      fontSize: 26,
      fontWeight: "bold",
      textAlign: "center",
    },
    days: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
    },
    dayOfWeeks: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      marginBottom: 8,
    },
    weeks: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      marginBottom: 4,
    },
    week: {
      minWidth: 175,
      height: 20,
      background: "#E7E7E7",
      fontSize: 11,
      lineHeight: 20,
      borderRadius: 10,
    },
  });
  useBodyClass(`home`);
  const dispatch = useDispatch();

  const { user, selectedUserId } = useSelector((state) => state.user);
  const { displayStaffVacation } = useSelector((state) => state.machine);
  const { workingRole } = user;
  const allowEdit = hasEditRight(workingRole);
  const calendarStartFrom =
    useSelector((state) => state.user.user.defaultFilter.customFilters)?.find(
      (item) => item.key === "calendarStartFrom"
    )?.text || "month";

  const [dates, setDates] = useState([]);
  const [calendar, setCalendar] = useState({});
  const [calendarStepWidth, setCalendarStepWidth] = useState(
    displayWeekView ? CALENDAR_WEEKVIEW_STEP_WIDTH : CALENDAR_STEP_WIDTH
  );
  const [usersData, setUsersData] = useState(null);
  const { data: usersOriginData } = useQuery("staffs", () =>
    dispatch(getStaffs())
  );
  const { data: allPlansData, refetch: refetchPlans } = useQuery("plans", () =>
    dispatch(getPlans(new Date(selectedDayOnCalendar).getFullYear()))
  );
  const holidayRef = useRef(null);
  const verticalPositionMap = useMemo(
    () => createVerticalPositionMap(dates),
    [dates]
  );

  useEffect(() => {
    if (usersOriginData)
      setUsersData(
        usersOriginData.filter((user) =>
          techDepartmentOptions.some(
            (department) => department.key === user.defaultFilter.techDepartmentFilter
          )
        )
      );
  }, [usersOriginData]);

  const onClickChooseDate = (date) => {
    dispatch(selectNewDate(date));
    const array = new Array(displayWeekView ? 12 : 6);
    array[0] = datesGenerator({
      month: date.getMonth(),
      year: date.getFullYear(),
      startingDay: 1,
    });
    for (let i = 1; i < array.length; i++) {
      array[i] = datesGenerator({
        month: array[i - 1].nextMonth,
        year: array[i - 1].nextYear,
        startingDay: 1,
      });
    }
    let { finalDates, start, end } = calculateFinalDates(array);
    if (calendarStartFrom === "month" && !displayWeekView) {
      finalDates[0] = startCalendarFromMonth(date, finalDates[0]);
    }
    // Find the start of calendar if choose to start from selected week
    if (calendarStartFrom === "week") {
      finalDates = startCalendarFromSelectedWeek(date, finalDates);
    }
    start = new Date(
      finalDates[0][0].year,
      finalDates[0][0].month,
      finalDates[0][0].date
    );
    // Find the holiday of calendar
    async function getDatesWithHolidays() {
      const yearOfDates = findYearOfDates(finalDates);
      let holidays = [];
      if (
        !holidayRef.current ||
        JSON.stringify(yearOfDates) !==
          JSON.stringify(holidayRef.current?.years)
      ) {
        for (const year of yearOfDates) {
          const currentHolidays = await dispatch(getHolidayList(year));
          holidays = [...holidays, ...(currentHolidays?.holidays || [])];
        }
        holidayRef.current = {
          years: yearOfDates,
          holidays: holidays,
        };
      } else {
        holidays = holidayRef.current.holidays;
      }
      finalDates = getCalendarHoliday(finalDates, holidays);
      setDates(finalDates);
    }
    getDatesWithHolidays();
    setDates(finalDates);

    setCalendar({
      ...calendar,
      nextMonth: array[array.length - 1].nextMonth,
      nextYear: array[array.length - 1].nextYear,
      previousMonth: array[0].previousMonth,
      previousYear: array[0].previousYear,
      start,
      end,
    });
  };

  useEffect(() => {
    refetchPlans();
    setCalendarStepWidth(
      displayWeekView ? CALENDAR_WEEKVIEW_STEP_WIDTH : CALENDAR_STEP_WIDTH
    );
    onClickChooseDate(new Date(selectedDayOnCalendar));
  }, [displayWeekView, new Date(selectedDayOnCalendar).toLocaleString()]);

  // Add icon to resize cursor
  useEffect(() => {
    setTimeout(() => {
      const resizeDivList = document.getElementsByClassName("react-draggable");
      for (let index = 0; index < resizeDivList.length; index++) {
        const resizeDiv = resizeDivList[index].lastChild;
        if (resizeDiv.firstChild && resizeDiv.lastChild) {
          resizeDiv.firstChild.style.cursor = allowEdit
            ? `url(${window.location.origin}/img/arrows-alt-h.svg), auto`
            : "default";
          resizeDiv.firstChild.style.left = "-14px";
          resizeDiv.lastChild.style.cursor = allowEdit
            ? `url(${window.location.origin}/img/arrows-alt-h.svg), auto`
            : "default";
          resizeDiv.lastChild.style.right = "3px";
        }
      }
    }, 500);
  });

  const filterStaffPlans = (usersData) => {
    const userData = usersData.find((user) => user.userId === selectedUserId);
    const result = { plan: [], vacation: [] };
    if (allPlansData?.length > 0) {
      allPlansData.forEach((plan) => {
        if (!plan.inactive) {
          plan.machineRequirements.forEach(
            (machineRequirement, indexMachineRequirement) => {
              [
                machineRequirement["drivers"],
                machineRequirement["workers"],
                machineRequirement["managers"],
              ].forEach((array) => {
                array.forEach((item) => {
                  if (userData.userId === item.id) {
                    let isDone = machineRequirement?.isHidden;
                    // if (
                    //     [machineRequirement?.drivers ?? [], machineRequirement?.workers ?? [], machineRequirement?.managers ?? []].some(
                    //         (array) => array.length === 0
                    //     ) &&
                    //     !machineRequirement?.done
                    // ) {
                    //     isDone = false;
                    // }
                    result.plan.push({
                      ...item,
                      machines: machineRequirement.machines,
                      personResponsbible: plan?.personResponsbible?.name,
                      isQuotation: plan.isQuotation,
                      projectNo: plan.projectNo,
                      projectId: plan.projectId,
                      projectName: plan.projectName,
                      hovedsagsNummer: plan.hovedsagsNummer,
                      color: plan.color,
                      indexMachineRequirement,
                      isDone,
                    });
                  }
                });
              });
            }
          );
        }
      });
    }
    if (userData?.starfVacations) {
      result.vacation = userData.starfVacations;
    }
    return result;
  };

  const userPlanAndVacation = useMemo(
    () =>
      usersData ? filterStaffPlans(usersData) : { plan: [], vacation: [] },
    [usersData, allPlansData, selectedUserId]
  );

  return usersData ? (
    <div>
      <PlanOverlay />
      <SelectUserOverlay usersData={usersData} />
      <div className={classNames.topContainer}>
        <CalendarHeaderLeft usersData={usersData} />
        <CalendarControlsRight
          onClickChooseDate={onClickChooseDate}
          calendarPage={CALENDAR_PAGE.DISPOSITION}
        />
      </div>
      <div className={classNames.calendarContainer}>
        <div className={classNames.weeks} style={{ width: 20, marginTop: 5 }}>
          {dates.length &&
            getMonthIndicator(dates, months).map((monthData, indexMonth) => (
              <MonthLabel
                isWeekView={displayWeekView}
                index={indexMonth}
                key={indexMonth + monthData["length"] + monthData["label"]}
                length={monthData["length"]}
                label={monthData["label"]}
              />
            ))}
        </div>

        <div className={classNames.weeks} style={{ marginLeft: 10 }}>
          {dates.length > 0 &&
            removeFirstWeek(
              dates,
              calendarStartFrom === "month" &&
                !displayWeekView &&
                Array.isArray(dates) &&
                dates[0]?.length < 7
            ).map((week, indexWeek) => (
              <WeekLabel
                isWeekView={displayWeekView}
                index={indexWeek}
                month={week[0]["month"]}
                day={week[0]["date"]}
                year={week[0]["year"]}
                key={`${week[0]["month"]}-${week[0]["date"]}-${week[0]["year"]}`}
              />
            ))}
        </div>
        {!displayWeekView && (
          <>
            <div className={classNames.days}>
              {dates.length > 0 &&
                dates.map((week) =>
                  week.map((each) => (
                    <DayLabel
                      label={each.date}
                      date={each}
                      key={JSON.stringify(each)}
                    />
                  ))
                )}
            </div>
            <div className={classNames.dayOfWeeks}>
              {dates.length > 0 &&
                dates.map((week) =>
                  week.map((each) => (
                    <DayLabel
                      label={each.date}
                      date={each}
                      key={JSON.stringify(each)}
                      isDaysOfWeek
                    />
                  ))
                )}
            </div>
          </>
        )}

        <div
          style={{
            minHeight: "calc(100vh - 305px)",
            height: calendarStepWidth * 7 * dates.length - 24,
            width: "calc(100vw - 125px)",
            backgroundImage: 'url("./img/calendarBackground3-vertical.png")',
            backgroundRepeat: "repeat",
            backgroundPositionY:
              calendarStartFrom === "month" &&
              !displayWeekView &&
              Array.isArray(dates) &&
              dates[0]?.length < 7
                ? dates[0].length * CALENDAR_STEP_WIDTH
                : 0,
          }}
        >
          <div
            id="planContainer"
            className="planContainer"
            style={{
              position: "absolute",
              top: 100,
              left: 100,
              zIndex: 556,
              height: calendarStepWidth * 7 * dates.length - 24,
            }}
          >
            {dates.length &&
              displayStaffVacation &&
              verticalPositionMap &&
              userPlanAndVacation.vacation.map((item, index) => (
                <Fragment key={`${item.start}-${index}`}>
                  <StaffVacationItem
                    calendarStepWidth={calendarStepWidth}
                    staffVacationIndex={index}
                    staffVacation={item}
                    start={item["start"]}
                    end={item["end"]}
                    verticalPositions={verticalPositionMap}
                    calendarStart={calendar.start}
                    calendarEnd={calendar.end}
                    isApproved={item["isApproved"]}
                  />
                </Fragment>
              ))}
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default VacationView;
