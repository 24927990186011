// Import libraries
import { app } from "@microsoft/teams-js";
import { useEffect, useState } from "react";

// Import utils
import { sleep } from "../utils/utils";

const useFrameContext = () => {
  const [checkedFrameContext, setCheckedFrameContext] = useState(false);
  const [isInTeamsApp, setIsInTeamsApp] = useState(false);

  useEffect(() => {
    const checkFrameContext = async () => {
      if (!checkedFrameContext) {
        await sleep(2000);
        const frameCtx = app.getFrameContext();

        setIsInTeamsApp(!!frameCtx);
        setCheckedFrameContext(true);
      }
    };

    checkFrameContext();
  }, [checkedFrameContext]);

  return {
    checkedFrameContext,
    isInTeamsApp,
  };
};

export default useFrameContext;
