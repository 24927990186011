import { MsalProvider } from "@azure/msal-react";
import { ThemeProvider } from "@fluentui/react";
import { useEffect, useState } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ConflictMessage, Message } from "./components/common";
import UserLeaveConfirmation from "./components/common/UserLeaveConfirmation";
import Login from "./components/pages/Login";
import MachineView from "./components/pages/machineView/MachineView";
import NotificationManagement from "./components/pages/NotificationManagement";
import Planning from "./components/pages/planning/Planning";
import VacationPlanning from "./components/pages/planning/VacationPlanning";
import PrivateRoute from "./components/routing/PrivateRoute";
import { getDefaultData } from "./redux/defaultData/defaultData.action";
import { setFilter } from "./redux/machines/machines.actions";
import { getCurrentUserInfo } from "./redux/user/user.actions";
import { appTheme } from "./utils/theme";
import Privacy from "./components/pages/Privacy";
import Terms from "./components/pages/Terms";

const App = ({ instance }) => {
  const queryClient = new QueryClient();
  const userId = useSelector((state) => state?.user?.user?.userId);
  const defaultFilter = useSelector(
    (state) => state?.user?.user?.defaultFilter
  );
  const filter = useSelector((state) => state.machine.filter);
  const dispatch = useDispatch();
  const { techAreaFilters, techDepartmentFilter } = defaultFilter ?? {
    techAreaFilters: null,
    techDepartmentFilter: null,
  };
  const [confirmOpen, setConfirmOpen] = useState(true);

  useEffect(() => {
    if (window.location.href.includes("/login")) {
      return;
    }
    dispatch(getDefaultData());
    dispatch(getCurrentUserInfo([userId]));
  }, [dispatch, filter]);

  useEffect(() => {
    if (techAreaFilters && techDepartmentFilter) {
      dispatch(
        setFilter({
          techDepartment: filter.techDepartment
            ? filter.techDepartment
            : techDepartmentFilter,
          [techDepartmentFilter]: {
            techArea: filter[techDepartmentFilter]?.techArea
              ? filter[techDepartmentFilter]?.techArea
              : techAreaFilters,
          },
        })
      );
    }
  }, [dispatch, techAreaFilters, techDepartmentFilter]);

  return (
    <MsalProvider instance={instance}>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={appTheme}>
          <Router
            getUserConfirmation={(message, callback) => {
              return UserLeaveConfirmation(
                message,
                callback,
                confirmOpen,
                setConfirmOpen
              );
            }}
          >
            <Switch>
              <Route exact path="/login">
                <Login />
              </Route>
              <Route exact path="/privacy">
                <Privacy />
              </Route>
              <Route exact path="/terms">
                <Terms />
              </Route>
              <Route exact path="/login-teams">
                <Login />
              </Route>
              <Route exact path="/configure-teams">
                <Login />
              </Route>
              <PrivateRoute exact path="/">
                <Planning />
              </PrivateRoute>
              <PrivateRoute exact path="/machine-view">
                <MachineView />
              </PrivateRoute>
              <PrivateRoute exact path="/vacation-view">
                <VacationPlanning />
              </PrivateRoute>
              <PrivateRoute exact path="/notification-management">
                <NotificationManagement />
              </PrivateRoute>
            </Switch>
          </Router>
          <Message />
          <ConflictMessage />
          <ReactQueryDevtools />
        </ThemeProvider>
      </QueryClientProvider>
    </MsalProvider>
  );
};

export default App;
