import React from 'react';
import { mergeStyleSets } from '@fluentui/react/lib/Styling';
import { CALENDAR_STEP_WIDTH, CALENDAR_WEEKVIEW_STEP_WIDTH } from '../../../utils/constants';

const classNames = mergeStyleSets({
    month: {
        fontSize: 11,
        lineHeight: 20,
        borderRadius: 10,
        fontWeight: 'bold',
        position: 'relative'
    },
    monthText: {
        marginLeft: 6,
        writingMode: 'tb',
    }
});

const MonthHeader = ({length, label = '', index, isWeekView = false}) => {
    return (
        <div style={{width: 20, minHeight: isWeekView ? CALENDAR_WEEKVIEW_STEP_WIDTH * length : CALENDAR_STEP_WIDTH * length }} className={classNames.month}>
            <div className={classNames.monthText}>
                { length > 10 ? label : '' }
            </div>
            {index !== 0 &&<div style={{position: 'absolute', top: 0, height: '100%', width: '20px' }}/>}
        </div>
    )
}

export default MonthHeader;
