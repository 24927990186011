import { mergeStyleSets } from "@fluentui/react/lib/Styling";
import moment from "moment";
import "moment/locale/da";
import React, { Fragment, useEffect, useRef, useState } from "react";
// import { useBoolean } from '@fluentui/react-hooks';
// import { useQueryClient } from 'react-query';
import { connect, useSelector } from "react-redux";
import { Rnd } from "react-rnd";
import { saveMessage } from "../../../redux/message/message.actions";
import { updateStaff } from "../../../redux/user/user.actions";
import {
  BAR_WIDTH,
  calendarConfirmationStatus,
  CALENDAR_STEP_HEIGHT,
  CALENDAR_STEP_WIDTH,
  CALENDAR_WEEKVIEW_STEP_WIDTH,
  hasEditRight,
  // reasonVacationStaffOptions,
} from "../../../utils/constants";
import { TooltipForText } from "../../common";

const StaffMachineServiceItem = ({
  serviceText,
  serviceItem,
  staffVacationIndex,
  start,
  end,
  verticalPositions,
  calendarStart,
  calendarEnd,
  isApproved,
  counter,
}) => {
  const userRoles = useSelector((state) => state.user.user.workingRole);
  let allowEdit = hasEditRight(userRoles);
  const calendarState = JSON.stringify(useSelector((state) => state.machine));
  const { displayWeekView } = useSelector((state) => state.machine);
  const calendarStepWidth = displayWeekView
    ? CALENDAR_WEEKVIEW_STEP_WIDTH
    : CALENDAR_STEP_WIDTH;
  const width = BAR_WIDTH;
  const [x, setX] = useState(0);
  const [y, setY] = useState(0);
  const [height, setHeight] = useState(0);
  const [display, setDisplay] = useState(false);
  const [confirmation, setConfirmation] = useState(
    calendarConfirmationStatus.CONFIRMATION_UNSET
  );
  const needConfirmation =
    confirmation !== calendarConfirmationStatus.CONFIRMATION_UNSET;
  const colorCode = "255, 255, 255";
  let lighterColorCode = "241,241,241";
  if (isApproved) lighterColorCode = colorCode;
  let bgColor = `repeating-linear-gradient(90deg, rgb(${colorCode}), rgb(${colorCode}) 24px, rgb(${lighterColorCode}) 24px, rgb(${lighterColorCode}) 48px)`;

  let calendarStepHeight = CALENDAR_STEP_HEIGHT;
  const ref = useRef(null);
  const renderDependencies =
    calendarState +
    JSON.stringify(verticalPositions) +
    JSON.stringify(serviceItem);
  if (!ref.current) ref.current = renderDependencies;
  const shouldComponentRerender = ref.current !== renderDependencies;

  const classNames = mergeStyleSets({
    icon: {
      color: "#fff",
      marginRight: 2,
      fontSize: 13,
      fontWeight: "lighter",
      selectors: {
        ":hover": {
          color: "#DBDBDB",
        },
      },
      cursor: "pointer",
    },
    draggable: {
      position: "relative",
      top: "50%",
      transform: "translateY(-50%)",
    },
    barContent: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      width: "calc(100% - 15px)",
      height: "100%",
    },
  });

  useEffect(() => {
    if (
      confirmation === calendarConfirmationStatus.CONFIRMATION_UNSET &&
      ((!x && !y) || shouldComponentRerender)
    ) {
      if (shouldComponentRerender) ref.current = renderDependencies;

      const keyStart = moment(start).utcOffset(0).format("YYYY-M-D");
      const keyEnd = moment(end).add(1, "d").utcOffset(0).format("YYYY-M-D");

      let xPosition = 0;
      let yPosition = verticalPositions[keyStart];
      let endValue = verticalPositions[keyEnd];

      if (new Date(start.slice(0, -1))?.getTime() <= calendarStart?.getTime()) {
        yPosition = 0;
      }

      if (new Date(end.slice(0, -1))?.getTime() >= calendarEnd?.getTime()) {
        endValue = verticalPositions["end"];
      }
      setY(yPosition * calendarStepHeight);

      if (typeof yPosition !== "undefined") {
        setX(xPosition * calendarStepWidth);
        setHeight((endValue - yPosition) * calendarStepHeight);
        setDisplay(true);
      } else {
        setDisplay(false);
      }
    }
  }, [
    start,
    end,
    verticalPositions,
    verticalPositions,
    calendarStart,
    calendarEnd,
    calendarStepWidth,
  ]);

  const text = serviceText || "";

  let content = (
    <div
      id={`staffVacationBar-${staffVacationIndex}`}
      className={`'dragDisabled'} ${classNames.barContent}`}
      style={{ marginLeft: 10, justifyContent: "flex-start" }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            alignItems: "flex-end",
            marginTop: 4,
          }}
        >
          <span
            style={{
              color: "#868685",
              fontWeight: "bold",
              fontSize: 11,
              marginRight: 8,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {text}
            {/* <span style={{ color: '#868685', fontWeight: 400, fontSize: 11, marginLeft: 8 }}>{staffVacation['notes'] || ''}</span> */}
          </span>
        </div>
      </div>
    </div>
  );
  if (height <= calendarStepWidth * 2) {
    content = (
      <div
        id={`staffVacationBar-${staffVacationIndex}`}
        className={`${
          allowEdit && !needConfirmation ? "dragHandle" : "dragDisabled"
        } ${classNames.barContent}`}
        style={{ justifyContent: "center" }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            height: "100%",
            width: "calc(100% - 5px)",
            marginLeft: 10,
          }}
        >
          <div
            style={{
              color: "#868685",
              fontWeight: "bold",
              fontSize: 11,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {text}
          </div>
        </div>
      </div>
    );
  }

  return (
    display &&
    height > 0 && (
      <Fragment>
        <Rnd
          style={{ zIndex: 3, top: 0, left: 0, display: "absolute" }}
          size={{ width: width, height: height }}
          position={{ x: x, y: y }}
          enableResizing={{ left: false, right: false }}
          resizeGrid={[calendarStepWidth, calendarStepHeight]}
          dragGrid={[calendarStepWidth, calendarStepHeight]}
          dragAxis={"none"}
          bounds=".planContainer"
          dragHandleClassName="dragHandle"
        >
          <div
            id={JSON.stringify(serviceItem)}
            className={classNames.draggable}
            style={{
              backgroundImage: bgColor,
              backgroundSize: "48px 48px",
              backgroundPosition: "0 0, 0 0, -24px -24px, 24px 24px",
              display: "flex",
              height: height,
              borderRadius: 4,
              border: "2px solid #868685",
              boxSizing: "border-box",
            }}
          >
            <TooltipForText text={text}>{content}</TooltipForText>
          </div>
        </Rnd>
      </Fragment>
    )
  );
};

export default connect(null, { updateStaff, saveMessage })(
  StaffMachineServiceItem
);
