import { mergeStyleSets } from '@fluentui/react/lib/Styling';
import moment from 'moment';
import 'moment/locale/da';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Rnd } from 'react-rnd';
import { setPlanOverlayProjectId } from '../../../redux/plan/plan.actions';
import { BAR_WIDTH, CALENDAR_STEP_HEIGHT, CALENDAR_STEP_WIDTH, CALENDAR_WEEKVIEW_STEP_WIDTH } from '../../../utils/constants';
import { TooltipForText } from '../../common/index';

const MachineBar = ({
    id,
    name,
    color,
    lighterColor,
    textColor,
    machine,
    start,
    end,
    hovedsagsNummer,
    verticalPositionMap,
    horizontalPositionMap,
    calendarStart,
    calendarEnd,
    indexMachineRequirement,
    type,
    borderWidth,
    borderStyle,
    borderColor,
    // isGhost,
    isHidden
}) => {
    const dispatch = useDispatch();
    const calendarState = JSON.stringify(useSelector((state) => state.machine));
    const { displayWeekView } = useSelector((state) => state.machine);
    const calendarStepWidth = displayWeekView ? CALENDAR_WEEKVIEW_STEP_WIDTH : CALENDAR_STEP_WIDTH;
    const width = BAR_WIDTH;
    const [x, setX] = useState(0);
    const [y, setY] = useState(0);
    const [height, setHeight] = useState(0);
    const [display, setDisplay] = useState(false);

    if (isHidden) name = 'Reservation/Tilbud';

    let bgColor = `repeating-linear-gradient(90deg, rgb(${color}), rgb(${color}) 24px, rgb(${lighterColor}) 24px, rgb(${lighterColor}) 48px)`;

    let calendarStepHeight = CALENDAR_STEP_HEIGHT;
    const ref = useRef(null);
    const renderDependencies = calendarState + JSON.stringify(verticalPositionMap) + JSON.stringify(machine);
    if (!ref.current) ref.current = renderDependencies;
    const shouldComponentRerender = ref.current !== renderDependencies;

    const classNames = mergeStyleSets({
        icon: {
            color: '#fff',
            marginRight: 2,
            fontSize: 13,
            fontWeight: 'lighter',
            selectors: {
                ':hover': {
                    color: '#DBDBDB',
                },
            },
            cursor: 'pointer',
        },
        draggable: {
            position: 'relative',
            top: '50%',
            transform: 'translateY(-50%)',
        },
        barContent: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            width: 'calc(100% - 15px)',
            height: '100%',
            cursor: 'pointer',
        },
    });

    useEffect(() => {
        if ((!x && !y) || shouldComponentRerender) {
            if (shouldComponentRerender) ref.current = renderDependencies;

            const keyStart = moment(start).utcOffset(0).format('YYYY-M-D');
            const keyEnd = moment(end).add(1, 'd').utcOffset(0).format('YYYY-M-D');

            setX(horizontalPositionMap[machine.machineId]);
            let yPosition = verticalPositionMap[keyStart];
            let endValue = verticalPositionMap[keyEnd];

            if (new Date(start.slice(0, -1))?.getTime() <= calendarStart?.getTime()) {
                yPosition = 0;
            }

            if (new Date(end.slice(0, -1))?.getTime() >= calendarEnd?.getTime()) {
                endValue = verticalPositionMap['end'];
            }
            setY(yPosition * calendarStepHeight);

            if (typeof yPosition !== 'undefined') {
                setHeight((endValue - yPosition) * calendarStepHeight);
                setDisplay(true);
            } else {
                setDisplay(false);
            }
        }
    }, [verticalPositionMap, verticalPositionMap, calendarStart, calendarEnd, machine]);

    let content = (
        <div
            id={`${id}-${machine.machineId}-machineBar-${type}-${indexMachineRequirement}`}
            className={`'dragDisabled' ${classNames.barContent}`}
            style={{ marginLeft: 10, justifyContent: 'flex-start' }}
        >
            <div style={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100%', overflow: 'hidden' }}>
                {type === 'plan' && !isHidden && <div style={{ marginTop: 10, color: textColor, fontSize: 11 }}>{hovedsagsNummer || 'Unknown number'}</div>}
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%',
                        alignItems: 'flex-end',
                        marginTop: 4,
                    }}
                >
                    <TooltipForText text={name}>
                        <span
                            style={{
                                color: textColor,
                                fontWeight: 'bold',
                                fontSize: 11,
                                marginRight: 8,
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}
                        >
                            {name}
                        </span>
                    </TooltipForText>
                </div>
            </div>
        </div>
    );

    if (height < calendarStepHeight * 3) {
        content = (
            <div
                id={`${id}-${machine.machineId}-machineBar-${type}-${indexMachineRequirement}`}
                className={`'dragDisabled' ${classNames.barContent}`}
                style={{ alignItems: 'center' }}
            >
                <div style={{ overflow: 'hidden', marginLeft: 5 }}>
                    <TooltipForText text={name}>
                        <span
                            style={{
                                color: textColor,
                                fontWeight: 'bold',
                                fontSize: 11,
                                marginRight: 8,
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}
                        >
                            {name}
                        </span>
                    </TooltipForText>
                </div>
            </div>
        );
    }

    return (
        display &&
        height > 0 && (
            <Fragment>
                <Rnd
                    style={{ zIndex: 3, top: 0, left: 0, display: 'absolute' }}
                    // default={{ x: x, y: y, width: width, height: 48}}
                    size={{ width: width, height: height }}
                    position={{ x: x, y: y }}
                    enableResizing={{ left: false, right: false }}
                    resizeGrid={[calendarStepWidth, calendarStepHeight]}
                    dragGrid={[calendarStepWidth, calendarStepHeight]}
                    dragAxis={'none'}
                    bounds='.machinePlanContainer'
                    dragHandleClassName='dragHandle'
                >
                    <div
                        className={classNames.draggable}
                        style={{
                            backgroundImage: bgColor,
                            backgroundSize: '48px 48px',
                            backgroundPosition: '0 0, 0 0, -24px -24px, 24px 24px',
                            display: 'flex',
                            height: height,
                            borderRadius: 4,
                            boxSizing: 'border-box',
                            borderWidth,
                            borderStyle,
                            borderColor,
                        }}
                        onClick={() => type === 'plan' && !isHidden && dispatch(setPlanOverlayProjectId(id, indexMachineRequirement))}
                    >
                        {content}
                    </div>
                </Rnd>
            </Fragment>
        )
    );
};

export default MachineBar;
