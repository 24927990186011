import axios from "axios";
import { checkTokenValidity } from "../user/user.actions";
import { saveMessage } from "../message/message.actions";
import { MessageBarType } from "@fluentui/react";
import { PlanActionTypes } from "./plan.types";
import setDefaults from "../../utils/setDefaults";
import { SOURCE_API } from "../../utils/constants";
import moment from "moment";

export const getPlans =
  (year = new Date().getFullYear(), fromDate, toDate) =>
  async (dispatch) => {
    try {
      setDefaults(localStorage.getItem("token"));
      // let from = new Date(`${year - 1}-1-1`).toISOString();
      // let to = new Date(`${year + 1}-12-31`).toISOString();
      let from = moment(`${year - 1}-1-1`, "YYYY-MM-DD HH:mm:ss").toISOString();
      let to = moment(`${year + 1}-12-31`, "YYYY-MM-DD HH:mm:ss").toISOString();
      if (fromDate && toDate) {
        from = fromDate;
        to = toDate;
      }
      const response = await axios.get(
        `${SOURCE_API}/api/Planning/getall?from=${from}&to=${to}`
      );

      return response.data.data;
    } catch (error) {
      dispatch(checkTokenValidity(error));
    }
  };

export const getPlan = (projectId) => async (dispatch) => {
  try {
    setDefaults(localStorage.getItem("token"));

    const response = await axios.get(
      `${SOURCE_API}/api/Planning/get?projectIds=${projectId}`
    );
    return response.data.data[0] ? response.data.data[0] : null;
  } catch (error) {
    dispatch(checkTokenValidity(error));
  }
};

export const createPlan = (planData, bookingId) => async (dispatch) => {
  try {
    setDefaults(localStorage.getItem("token"));
    const url = bookingId
      ? `${SOURCE_API}/api/Planning?bookingId=${bookingId}`
      : `${SOURCE_API}/api/Planning`;
    const response = await axios.post(url, planData);

    if (response.data.succeeded === true) {
      dispatch(saveMessage("Plan successfully saved!", MessageBarType.success));
    } else {
      dispatch(saveMessage("There was an error"));
    }
    return response.data.succeeded;
  } catch (error) {
    dispatch(checkTokenValidity(error));
  }
};

export const updatePlan = (planData, bookingId) => async (dispatch) => {
  try {
    setDefaults(localStorage.getItem("token"));
    const url = bookingId
      ? `${SOURCE_API}/api/Planning?bookingId=${bookingId}`
      : `${SOURCE_API}/api/Planning`;
    const response = await axios.put(url, planData);

    if (response.data.succeeded === true) {
      dispatch(
        saveMessage("Plan successfully updated!", MessageBarType.success)
      );
    } else {
      dispatch(saveMessage("There was an error"));
    }
    return response.data.succeeded;
  } catch (error) {
    dispatch(checkTokenValidity(error));
  }
};

export const deletePlan = (projectId) => async (dispatch) => {
  try {
    setDefaults(localStorage.getItem("token"));
    const response = await axios.delete(
      `${SOURCE_API}/api/Planning?projectId=${projectId}`
    );

    if (response.data.succeeded === true) {
      dispatch(saveMessage("Plan deleted", MessageBarType.success));
    } else {
      dispatch(saveMessage("There was an error"));
    }
    return response.data.succeeded;
  } catch (error) {
    dispatch(checkTokenValidity(error));
  }
};

export const setPlanOverlayProjectId =
  (projectId, index) => async (dispatch) => {
    dispatch({
      type: PlanActionTypes.UPDATE_OVERLAY_PROJECT_ID,
      payload: { projectId, index },
    });
  };

export const clearPlanOverlayProjectId = () => async (dispatch) => {
  dispatch({
    type: PlanActionTypes.CLEAR_OVERLAY_PROJECT_ID,
  });
};

export const getActivityByResourceIdAndResourceType =
  (resourceId, resourceType) => async (dispatch) => {
    try {
      setDefaults(localStorage.getItem("token"));
      const response = await axios.get(
        `${SOURCE_API}/api/activitylogger?resourceId=${resourceId}&resourceType=${resourceType}`
      );
      return response.data.data;
    } catch (error) {
      dispatch(checkTokenValidity(error));
    }
  };
